import { Component } from '@angular/core';

@Component({
  selector: 'app-button-add-card',
  standalone: true,
  imports: [],
  templateUrl: './button-add-card.component.html',
  styleUrl: './button-add-card.component.scss',
})
export class ButtonAddCardComponent {}

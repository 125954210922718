import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { chunk } from 'lodash-es';

import { HeaderComponent } from '@components/common/header/header.component';
import { MobileFooterComponent } from '@components/common/mobile-footer/mobile-footer.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TitleComponent } from '@components/common/title/title.component';
import { TBasicContacts } from './core/contactsTypes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { PhoneNumberPipe } from '@pipes/phone-number.pipe';
import { RequisitesComponent } from './requisites/requisites.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ContactsFooterComponent } from './contacts-footer/contacts-footer.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';

import { ContactsService } from './core/contacts.service';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    HeaderComponent,
    MobileFooterComponent,
    TitleComponent,
    MobileHeaderComponent,
    PhoneNumberPipe,
    RequisitesComponent,
    InfoBlockComponent,
    ContactsFooterComponent,
    LoaderGlobalComponent,
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent implements OnInit {
  contactsService = inject(ContactsService);
  uiService = inject(UIService);

  isMobile = this.uiService.isMobile;

  loading = signal(false);
  error = signal<string | null>(null);
  contacts = signal<TBasicContacts | null>(null);

  phones = computed(() => {
    const allPhones = this.contacts()?.phones;

    if (allPhones) {
      return chunk(allPhones, 2);
    } else {
      return [];
    }
  });

  ngOnInit(): void {
    this.loading.set(true);

    this.contactsService.loadBasicContacts().subscribe({
      next: (response) => {
        this.contacts.set(response);
        this.error.set(null);
        this.loading.set(false);
      },
      error: (error: TFormattedApiError) => {
        if (error.formattedErrorMessage)
          this.error.set(error.formattedErrorMessage);
        this.loading.set(false);
      },
    });
  }
}

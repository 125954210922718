import { Injectable, computed, signal } from '@angular/core';
import { Observable, finalize, interval, map, of, scan, takeWhile } from 'rxjs';

import { TIME_BETWEEN_REQUESTS_SMS } from '@settings/constants';
import { getTimeFromMilliseconds } from '@utils/getTimeFromMilliseconds';

@Injectable()
export class TimerService {
  #timerValue = signal(getTimeFromMilliseconds(TIME_BETWEEN_REQUESTS_SMS));
  timerValue = computed(this.#timerValue);

  #isCompletedTimer = signal(true);
  isCompletedTimer = computed(this.#isCompletedTimer);

  reSendTimer$: Observable<number | null> = of(null);

  createTimer() {
    this.#isCompletedTimer.set(false);

    return (this.reSendTimer$ = interval(1000).pipe(
      scan((acc) => {
        this.#timerValue.set(getTimeFromMilliseconds(acc));

        return (acc = acc - 1000);
      }, TIME_BETWEEN_REQUESTS_SMS - 1000),
      map((acc) => {
        return acc;
      }),
      takeWhile((value) => value >= 0),
      finalize(() => {
        this.#isCompletedTimer.set(true);
      }),
    ));
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-transparent-label',
  standalone: true,
  imports: [],
  templateUrl: './transparent-label.component.html',
  styleUrl: './transparent-label.component.scss',
})
export class TransparentLabelComponent {}

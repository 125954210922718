<div class="advertising-banner-big">
  <app-button-link [link]="trialLink" class="trial-button"
    >Попробовать бесплатно
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7 7L17 7V17"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 17L17 7"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      /></svg>
  </app-button-link>

  <div class="data">
    <div class="title">Бесплатный пробный месяц подписки</div>
    <div class="labels-block">
      <app-transparent-label
        >Доступ в раздел «Аналитика цен»</app-transparent-label
      >
      <app-transparent-label>Открытие контактов – 50 шт </app-transparent-label>
      <app-transparent-label
        >Просмотр графиков изменения цены</app-transparent-label
      >
    </div>
  </div>
</div>

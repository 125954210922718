<input
  type="text"
  inputmode="numeric"
  #input_number
  [placeholder]="placeholder()"
  [disabled]="disabled()"
  [(ngModel)]="value"
  (input)="changeValue.emit($event)"
  (focus)="handleFocus()"
  (blur)="handleBlur()"
  (keydown)="handleKeyDown($event)"
  autocomplete="one-time-code"
  class="input-confirmation-block-number"
  [class.error]="!!error()"
/>

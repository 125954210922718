<div class="requisites-title">Реквизиты</div>

<div *ngIf="requisites()?.name" class="requisites-item">
  Наименование организации
  <span>{{ requisites()?.name }}</span>
</div>
<div *ngIf="requisites()?.inn" class="requisites-item">
  ИНН
  <span>{{ requisites()?.inn }}</span>
</div>
<div *ngIf="requisites()?.kpp" class="requisites-item">
  КПП
  <span>{{ requisites()?.kpp }}</span>
</div>
<div *ngIf="requisites()?.ogrn" class="requisites-item">
  ОГРН
  <span>{{ requisites()?.ogrn }}</span>
</div>

<div *ngIf="requisites()?.legal_address" class="requisites-item address">
  Юридический адрес
  <span>{{ requisites()?.legal_address }}</span>
</div>

<div *ngIf="requisites()?.actual_address" class="requisites-item address">
  Фактический адрес
  <span>{{ requisites()?.actual_address }}</span>
</div>

import { Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_FAVOURITES,
  ROUTE_PROFILE,
} from '@settings/routes';
import { footerMenuList, footerMenuListOpen } from '@settings/footerMenuLists';
import { SearchComponent } from '@components/ui/search/search.component';
import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { ContactBlockComponent } from './contact-block/contact-block.component';
import { IconButtonLinkComponent } from '@components/ui/icon-button-link/icon-button-link.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { LogoLinkComponent } from '../logo-link/logo-link.component';

import { AuthService } from '@services/auth.service';
import { ContactsService } from '@components/pages/contacts/core/contacts.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink,
    MenuLinkComponent,
    ButtonLinkComponent,
    IconButtonComponent,
    IconButtonLinkComponent,
    SearchComponent,
    ContactBlockComponent,
    LogoLinkComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  authService = inject(AuthService);
  contactsService = inject(ContactsService);

  contacts = this.contactsService.footerContacts;

  isAuth = this.authService.isAuth;
  menuList = computed(() =>
    this.isAuth() ? footerMenuList : footerMenuListOpen,
  );

  year = new Date().getFullYear();

  authLink = ROUTE_AUTH;
  favouritesLink = `${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`;
  announcementCreateLink = ROUTE_ANNOUNCEMENT_CREATE;

  // Контактные данные
  emails = computed(() => this.contacts()?.emails || []);
  phones = computed(() => this.contacts()?.phones || []);
  socials = computed(() => this.contacts()?.socials || []);

  scrollToTop(): void {
    document.getElementById('root')?.scrollIntoView();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFullName',
  standalone: true,
})
export class UserFullNamePipe implements PipeTransform {
  transform({
    fistName,
    lastName,
    middleName,
  }: {
    fistName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  }): string {
    if (!lastName) {
      return '';
    }

    if (fistName) {
      return `${lastName} ${fistName.slice(0, 1)}. ${middleName ? `${middleName.slice(0, 1)}.` : ''}`;
    }

    return lastName;
  }
}

import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MatIcon } from '@angular/material/icon';

import { ButtonComponent } from '@components/ui/button/button.component';

@Component({
  selector: 'app-resetting-priorities-dialog',
  standalone: true,
  imports: [ButtonComponent, MatDialogModule, MatIcon],
  templateUrl: './resetting-priorities-dialog.component.html',
  styleUrl: '../dialog.component.scss',
})
export class ResettingPrioritiesDialogComponent {}

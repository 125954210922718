@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title>Подписка</app-title>
  </app-mobile-header>
}
<div
  class="subscription-info-block"
  [class.active]="subscriptionService.isActiveSubscription()"
>
  @if (subscriptionService.isActiveSubscription()) {
    <div class="subscription-title-block">
      <div class="icon-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <rect y="0.5" width="24" height="24" rx="12" fill="#6F9305" />
          <path
            d="M16.6 9.5L10 15.9L7 12.9909"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <app-title *ngIf="subscriptionService.isDemoSubscription()"
          >Пробный период активен</app-title
        >
        <app-title *ngIf="!subscriptionService.isDemoSubscription()"
          >Подписка оформлена</app-title
        >
      </div>

      <app-button
        variety="grey"
        [disabled]="loading()"
        (pressed)="openCancelDialog()"
        *ngIf="!isMobile()"
        >Отменить подписку</app-button
      >
    </div>
  }

  @if (!subscriptionService.isActiveSubscription()) {
    <div class="subscription-title-block">
      <div class="icon-title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <rect y="0.5" width="24" height="24" rx="12" fill="#FF5B65" />
          <path
            d="M16.1992 8.70068L8.19922 16.7007"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.19922 8.70068L16.1992 16.7007"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <app-title>Подписка неактивна</app-title>
      </div>

      <app-button
        variety="white"
        [disabled]="loading()"
        (pressed)="openActivateDialog()"
        *ngIf="!isMobile()"
        >Активировать подписку</app-button
      >
    </div>
  }

  <div class="dates-block">
    <div *ngIf="subscription()?.subscription_started_at" class="item-title">
      Дата оформления:
      <span>{{
        subscription()?.subscription_started_at | date: "dd.MM.yyyy"
      }}</span>
    </div>

    <div
      *ngIf="
        !subscriptionService.isActiveSubscription() &&
        subscription()?.subscription_canceled_at
      "
      class="item-title"
    >
      Дата деактивации:
      <span>{{
        subscription()?.subscription_canceled_at | date: "dd.MM.yyyy"
      }}</span>
    </div>
  </div>

  <!-- Для неактивной подписки -->
  <div
    *ngIf="subscription()?.subscription_status?.value === 'ended'"
    class="item-title"
  >
    Подписка недействительна у всех сотрудников организации.
  </div>

  <!-- Для подписки в процессе отмены -->
  <div *ngIf="subscriptionService.isCancelingSubscription()" class="item-title">
    Вы можете пользоваться преимуществами подписки до окончания оплаченного
    периода
  </div>

  <div *ngIf="subscription()?.subscription_next_period" class="item-title">
    <span
      >{{
        subscription()?.subscription_next_period?.price
          | number: "1.0" : "ru-Ru"
      }}
      руб</span
    >
    спишется
    {{
      subscription()?.subscription_next_period?.date | date: "dd MMMM" : "ru-Ru"
    }}
  </div>

  <div *ngIf="subscription()?.subscription_next_period" class="item-title">
    <span>{{
      subscription()?.subscription_next_period?.date | date: "dd MMMM" : "ru-Ru"
    }}</span>
    обновится счет открытия контактов, добавленных по подписке:
    <span>{{ subscription()?.subscription_openings_left }}</span>
  </div>

  <!-- Кнопки для адаптива -->
  <app-button
    variety="grey"
    (pressed)="openCancelDialog()"
    [disabled]="loading()"
    *ngIf="isMobile() && subscriptionService.isActiveSubscription()"
    class="adaptive-btn"
    >Отменить подписку</app-button
  >

  <app-button
    variety="white"
    [disabled]="loading()"
    (pressed)="openActivateDialog()"
    *ngIf="isMobile() && !subscriptionService.isActiveSubscription()"
    class="adaptive-btn"
    >Активировать подписку</app-button
  >
</div>

@let contact_package_openings_left =
  subscription()?.contact_package_openings_left;

@if (!openContactsCount()) {
  <div class="open-contacts-block">Нет доступных открытий</div>
} @else {
  <div class="open-contacts-block active">
    Вам доступно {{ openContactsCount() | number: "1.0" : "ru-Ru" }}
    {{ openContactsCount() | declense: ["открытие", "открытия", "открытий"] }}

    @if (
      subscription()?.subscription_openings_left &&
      contact_package_openings_left
    ) {
      <div class="description">
        {{ subscription()?.subscription_openings_left }} по подписке и
        {{ subscription()?.contact_package_openings_left }}
        {{
          contact_package_openings_left
            | declense: ["купленное", "купленных", "купленных"]
        }}
        дополнительно
      </div>
    }

    @if (
      !subscription()?.subscription_openings_left &&
      contact_package_openings_left
    ) {
      <div class="description">
        {{ subscription()?.contact_package_openings_left }}
        {{
          contact_package_openings_left
            | declense: ["купленное", "купленных", "купленные"]
        }}
        дополнительно
      </div>
    }

    @if (
      subscription()?.subscription_openings_left &&
      !subscription()?.contact_package_openings_left
    ) {
      <div class="description">
        {{ subscription()?.subscription_openings_left }} по подписке
      </div>
    }
  </div>
}

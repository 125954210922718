import { Component, computed, input } from '@angular/core';

import { TPriceHistory } from '../core/priceAnalysisTypes';
import { PricePipe } from '@pipes/price.pipe';

@Component({
  selector: 'app-price-history',
  standalone: true,
  imports: [PricePipe],
  templateUrl: './price-history.component.html',
  styleUrl: './price-history.component.scss',
})
export class PriceHistoryComponent {
  variety = input<'announcements' | 'recycles'>('announcements');
  orientation = input<'horizontally' | 'vertically'>('horizontally');
  title = input.required<string>();

  history = input.required<TPriceHistory | undefined>();

  isNullData = computed(
    () =>
      this.history()?.avg === null &&
      this.history()?.max === null &&
      this.history()?.min === null,
  );
}

import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  afterNextRender,
  signal,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClass } from '@angular/common';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  throttleTime,
} from 'rxjs';

import { ROUTE_HOME } from '@settings/routes';
import { UIService } from '@services/ui.service';
import { AuthService } from '@services/auth.service';
import { CategoryLinkComponent } from './category-link/category-link.component';
import { AnnouncementCardComponent } from './announcement-card/announcement-card.component';
import { AnnouncementsListService } from './core/announcementsList.service';
import { ReferencesService } from '@services/references.service';
import { typeValues } from './core/announcementsListSettings';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { AdvertisingBigBannerComponent } from './advertising-big-banner/advertising-big-banner.component';
import { AdvertisingSmallBannerComponent } from './advertising-small-banner/advertising-small-banner.component';
import { CategoriesDrawerComponent } from './categories-drawer/categories-drawer.component';
import { PromoComponent } from './promo/promo.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { DataWrapperComponent } from '@components/common/data-wrapper/data-wrapper.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { SearchComponent } from '@components/ui/search/search.component';
import { SelectLocationComponent } from '@components/ui/select-location/select-location.component';
import { TAnnouncementType } from '@models/announcementTypes';
import { SproutIconComponent } from '@components/ui/icons/sprout-icon.component';
import { FavouritesService } from '../profile/profilePages/favourites/core/favourites.service';
import { TFormattedApiError } from '@utils/formattingApiError';
import { NotificationService } from '@services/notification.service';
import { TChangeFavourites } from './core/announcementsListTypes';
import { PriorityButtonComponent } from '@components/pages/home/priority-button/priority-button.component';
import { CategoriesFilterDrawerComponent } from '@components/pages/categories/categories-filter-drawer/categories-filter-drawer.component';
import { CategoriesFilterComponent } from '@components/pages/categories/categories-filter/categories-filter.component';
import { DrawerComponent } from '@components/common/drawer/drawer.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CategoryLinkComponent,
    AnnouncementCardComponent,
    NgClass,
    RouterLink,
    SproutIconComponent,
    AdvertisingBigBannerComponent,
    AdvertisingSmallBannerComponent,
    ButtonLinkComponent,
    CategoriesDrawerComponent,
    PromoComponent,
    LoaderGlobalComponent,
    DataWrapperComponent,
    MobileHeaderComponent,
    SearchComponent,
    SelectLocationComponent,
    PriorityButtonComponent,
    CategoriesFilterDrawerComponent,
    CategoriesFilterComponent,
    DrawerComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  isFavouritesLoading = signal(false);

  constructor(
    private announcementsService: AnnouncementsListService,
    private favouritesService: FavouritesService,
    private notificationService: NotificationService,
    private references: ReferencesService,
    private authService: AuthService,
    private uiService: UIService,
    private route: ActivatedRoute,
  ) {
    afterNextRender(() => {
      // Высота footer или мобильного footer + половина высоты карточки объявления
      const footerHeight =
        document.getElementById('footer')?.clientHeight || 70;

      const pageByScroll$ = fromEvent(window, 'scroll', { passive: true }).pipe(
        map(() => window.scrollY),
        filter(
          (scrollY) =>
            scrollY >=
            document.documentElement.scrollHeight -
              window.innerHeight -
              footerHeight -
              210,
        ),
        throttleTime(200),
        distinctUntilChanged(),
      );

      pageByScroll$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        if (!this.isLoading() && !this.isInfiniteLoading())
          this.announcementsService.loadAnnouncementsList({
            params: {
              type_value: this.announcementType,
            },
            isInfinite: true,
            url: 'announcements/main-page',
          });
      });
    });
  }

  isAuth = this.authService.isAuth;
  isLoading = this.announcementsService.loader;
  isInfiniteLoading = this.announcementsService.infiniteLoader;
  error = this.announcementsService.error;

  isMobile = this.uiService.isMobile;

  // Список объявлений
  announcementsList = this.announcementsService.announcementsList;

  listBeforeAdvertisement = computed(() => {
    return this.announcementsList().slice(0, 8);
  });
  listAfterAdvertisement = computed(() => {
    return this.announcementsList().slice(8, this.announcementsList().length);
  });

  // Список категорий
  mainPageCategoriesList = this.references.mainPageCategoriesList;
  isShowCategoriesDrawer = signal(false);
  isShowPriorityDrawer = signal(false);

  changeShowCategoriesDrawer(): void {
    this.isShowCategoriesDrawer.update((prev) => !prev);
    this.uiService.toggleMainScroll();
  }

  destroyRef = inject(DestroyRef);

  announcementType: TAnnouncementType = 'sell';

  homeLink = ROUTE_HOME;

  ngOnInit(): void {
    this.uiService.resetMainScroll();

    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        // раз досюда дошло то просто всё скидываем
        this.announcementsService.resetAnnouncementData();
        this.announcementType = result['type_value'] === 'buy' ? 'buy' : 'sell';

        const params =
          !!result['type_value'] && typeValues.includes(result['type_value'])
            ? { type_value: result['type_value'] }
            : { type_value: typeValues[0] };

        this.announcementsService.loadAnnouncementsList({
          params,
          url: 'announcements/main-page',
        });
      });
  }

  changeAnnouncementIsFavourite({ slug, is_favorite }: TChangeFavourites) {
    this.isFavouritesLoading.set(true);

    const changeFunction = !is_favorite
      ? this.favouritesService.addingAnnouncementToFavorites(slug)
      : this.favouritesService.deletingAnnouncementFromFavorites(slug);

    changeFunction.subscribe({
      next: () => {
        this.announcementsService.changeAnnouncementIsFavorite(slug);
        this.isFavouritesLoading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.isFavouritesLoading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }

  reloadAnnouncements() {
    this.ngOnInit();
    this.isShowPriorityDrawer.set(false);
  }
}

<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">Отменить подписку</div>

<mat-dialog-content class="dialog-content">
  Вы уверены, что хотите отменить подписку? Вы не сможете больше пользоваться
  преимуществами подписки.

  <div class="grey">
    Подписка будет действовать до {{ data.date | date: "dd.MM.yyyy" }}.
    Купленные дополнительно открытия контактов остаются доступными к
    использованию.
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true"
    >Отменить подписку</app-button
  >
  <app-button variety="transparent" mat-dialog-close>Назад</app-button>
</mat-dialog-actions>

<form name="phoneForm" #phoneForm="ngForm" class="profile-form">
  <app-info-block
    *ngIf="!!apiError()"
    [description]="apiError() || 'Неизвестная ошибка'"
    variety="error"
  />

  <hr class="top" />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Телефон
        <sup class="required">*</sup>
      </div>
      <div class="description-text">
        При изменении номера телефона необходимо подтверждение
      </div>
      <div
        *ngIf="!!phone_number.invalid && !!phone_number.touched"
        class="error"
      >
        Укажите корректный номер телефона
      </div>
    </div>
    <div class="value">
      <div class="contacts-block">
        <app-input-phone
          name="phone-number"
          id="phone-number"
          #phone_number="ngModel"
          [(ngModel)]="setPhoneForm.phone_number"
          [withLabel]="false"
          [required]="true"
          [pattern]="phonePattern"
          [disabled]="loading()"
          [error]="!!phone_number.invalid && !!phone_number.touched"
          class="input-phone"
        />
        <app-confirmation-information
          [variety]="phoneConfirmed() ? 'success' : 'error'"
          entity="Телефон"
        />
      </div>

      @if (!phoneConfirmed()) {
        @if (!isGetPhoneCode()) {
          <div class="timer-text">
            Отправить новый код через {{ timerPhoneValue() }}
          </div>
        }
        <!-- @else {
          <app-button-text (pressed)="changePhone()" [disabled]="loading()" class="timer-button"
            >Отправить новый код</app-button-text
          >
        } -->
      }
    </div>
  </div>
  @if (!phoneConfirmed()) {
    <div class="profile-form-item confirm">
      <div class="description">
        <div class="label">Введите код подтверждения</div>
      </div>
      <div class="contacts-block">
        <app-input-label
          id="phone-confirm"
          name="phone-confirm"
          #phone_confirm_code
          inputMask="000000"
          [maskTyped]="false"
          [disabled]="loading()"
          placeholder="Код"
          class="input-code"
        /><app-button
          type="button"
          (pressed)="confirmPhone(phone_confirm_code.value)"
          [disabled]="
            loading() || phone_confirm_code.value.length !== codeLength
          "
          (click)="captchaPhone.execute()"
          >Подтвердить</app-button
        >
      </div>
    </div>
  }

  <hr />
  <re-captcha
    #captchaPhone
    size="invisible"
    (resolved)="resolvedPhone($event)"
    [siteKey]="key"
  ></re-captcha>

  <app-button
    type="button"
    variety="secondary"
    [disabled]="
      !!phoneForm.invalid ||
      loading() ||
      profileData()?.phone_number === '+7' + setPhoneForm.phone_number ||
      !isGetPhoneCode()
    "
    (pressed)="changePhone()"
    (click)="captchaPhone.execute()"
    >{{ phoneConfirmed() ? "Изменить телефон" : "Новый код" }}</app-button
  >
</form>

<form name="emailForm" #emailForm="ngForm" class="profile-form">
  <hr />
  <div class="profile-form-item">
    <div class="description">
      <div class="label">
        Email
        <sup class="required">*</sup>
      </div>
      <div class="description-text">
        При изменении email необходимо подтверждение
      </div>
      <div *ngIf="!!email.invalid && !!email.touched" class="error">
        Укажите корректный email
      </div>
    </div>
    <div class="value">
      <div class="contacts-block">
        <app-input-label
          id="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="setEmailForm.email"
          placeholder="Email"
          [disabled]="loading()"
          [required]="true"
          [pattern]="emailPattern"
          [maxlength]="50"
          [error]="!!email.invalid && !!email.touched"
          class="item-input"
        />
        <app-confirmation-information
          [variety]="emailConfirmed() ? 'success' : 'error'"
          entity="Email"
        />
      </div>

      @if (!emailConfirmed()) {
        @if (!isGetEmailCode()) {
          <div class="timer-text">
            Отправить новый код через {{ timerEmailValue() }}
          </div>
        }
        <!-- @else {
          <app-button-text
            (pressed)="changeEmail()"
            [disabled]="loading()"
            >Отправить новый код</app-button-text
          >
        } -->
      }
    </div>
  </div>

  @if (!emailConfirmed()) {
    <div class="profile-form-item confirm">
      <div class="description">
        <div class="label">Введите код подтверждения</div>
      </div>
      <div class="contacts-block">
        <app-input-label
          id="email-confirm"
          name="email-confirm"
          #email_confirm_code
          inputMask="000000"
          [maskTyped]="false"
          [disabled]="loading()"
          placeholder="Код"
          class="input-code"
        /><app-button
          type="button"
          (pressed)="confirmEmail(email_confirm_code.value)"
          [disabled]="
            loading() || email_confirm_code.value.length !== codeLength
          "
          (click)="captchaEmail.execute()"
          >Подтвердить</app-button
        >
      </div>
    </div>
  }

  <hr />
  <re-captcha
    #captchaEmail
    size="invisible"
    (resolved)="resolvedEmail($event)"
    [siteKey]="key"
  ></re-captcha>

  <app-button
    type="button"
    variety="secondary"
    [disabled]="
      !!emailForm.invalid ||
      loading() ||
      profileData()?.email === setEmailForm.email ||
      !isGetEmailCode()
    "
    (pressed)="changeEmail()"
    (click)="captchaEmail.execute()"
    >{{ emailConfirmed() ? "Изменить email" : "Новый код" }}</app-button
  >
</form>

import { Routes } from '@angular/router';

import {
  ROUTE_ANNOUNCEMENT,
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_ANNOUNCEMENT_EDIT,
  ROUTE_AUTH,
  ROUTE_CATEGORY,
  ROUTE_CONTACTS,
  ROUTE_FAVOURITES,
  ROUTE_FAVOURITES_USERS,
  ROUTE_HOME,
  ROUTE_MAP,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_MY_ANNOUNCEMENTS_DISABLE,
  ROUTE_MY_ANNOUNCEMENTS_DRAFT,
  ROUTE_OPEN_CONTACTS,
  ROUTE_PASSWORD_RECOVERY,
  ROUTE_PRICE_ANALYSIS,
  ROUTE_PROFILE,
  ROUTE_PROFILE_CONTACTS,
  ROUTE_PROFILE_PRIORITY,
  ROUTE_PROFILE_SAFETY,
  ROUTE_REGISTRATION,
  ROUTE_REGISTRATION_CONFIRM,
  ROUTE_SETTINGS,
  ROUTE_SUBSCRIPTION,
  ROUTE_USER_CONTACTS,
  ROUTE_USER_CONTACTS_DISABLED,
} from '@settings/routes';

import { MainLayoutComponent } from '@components/layouts/main-layout/main-layout.component';

import { authGuard } from './guards/auth.guard';
import { nonAuthGuard } from './guards/non-auth.guard';

// todo lazy load
import { HomeComponent } from '@components/pages/home/home.component';

import { AuthComponent } from '@components/pages/auth/auth.component';
import { RegistrationComponent } from '@components/pages/registration/registration.component';
import { PasswordRecoveryComponent } from '@components/pages/password-recovery/password-recovery.component';

import { EmailConfirmationComponent } from '@components/pages/email-confirmation/email-confirmation.component';

import { NotFoundComponent } from '@components/common/not-found/not-found.component';
import { ContactsComponent } from '@components/pages/contacts/contacts.component';
import { PriceAnalysisComponent } from '@components/pages/price-analysis/price-analysis.component';

import { ProfileComponent } from '@components/pages/profile/profile.component';

import { SettingsProfileComponent } from '@components/pages/profile/profilePages/settings-profile/settings-profile.component';
import { OpenContactsComponent } from '@components/pages/profile/profilePages/open-contacts/open-contacts.component';
import { SubscriptionComponent } from '@components/pages/profile/profilePages/subscription/subscription.component';
import { PersonalDataComponent } from '@components/pages/profile/profilePages/settings-profile/tabs/personal-data/personal-data.component';
import { SafetyComponent } from '@components/pages/profile/profilePages/settings-profile/tabs/safety/safety.component';
import { PriorityComponent } from '@components/pages/profile/profilePages/priority/priority.component';
import { ProfileContactsComponent } from '@components/pages/profile/profilePages/settings-profile/tabs/profile-contacts/profile-contacts.component';

import { MainProfileComponent } from '@components/pages/profile/profilePages/main-profile/main-profile.component';
import { MyAnnouncementsComponent } from '@components/pages/profile/profilePages/my-announcements/my-announcements.component';
import { MyAnnouncementsActiveComponent } from '@components/pages/profile/profilePages/my-announcements/tabs/my-announcements-active/my-announcements-active.component';
import { MyAnnouncementsDisableComponent } from '@components/pages/profile/profilePages/my-announcements/tabs/my-announcements-disable/my-announcements-disable.component';
import { MyAnnouncementsDraftComponent } from '@components/pages/profile/profilePages/my-announcements/tabs/my-announcements-draft/my-announcements-draft.component';

import { FavouritesComponent } from '@components/pages/profile/profilePages/favourites/favourites.component';
import { FavouritesAnnouncementsComponent } from '@components/pages/profile/profilePages/favourites/tabs/favourites-announcements/favourites-announcements.component';
import { FavouritesUsersComponent } from '@components/pages/profile/profilePages/favourites/tabs/favourites-users/favourites-users.component';

import { UserAnnouncementsActiveComponent } from '@components/pages/user-contacts/tabs/user-announcements-active/user-announcements-active.component';
import { UserAnnouncementsDisableComponent } from '@components/pages/user-contacts/tabs/user-announcements-disable/user-announcements-disable.component';

const userContactsRoutes: Routes = [
  {
    path: '',
    component: UserAnnouncementsActiveComponent,
  },
  {
    path: ROUTE_USER_CONTACTS_DISABLED,
    component: UserAnnouncementsDisableComponent,
  },
];

const mainProfileRoutes: Routes = [
  {
    path: '',
    component: MainProfileComponent,
  },
  // Профиль - Мои объявления
  {
    path: ROUTE_MY_ANNOUNCEMENTS,
    component: MyAnnouncementsComponent,
    children: [
      {
        path: '',
        component: MyAnnouncementsActiveComponent,
      },
      {
        path: ROUTE_MY_ANNOUNCEMENTS_DISABLE,
        component: MyAnnouncementsDisableComponent,
      },
      {
        path: ROUTE_MY_ANNOUNCEMENTS_DRAFT,
        component: MyAnnouncementsDraftComponent,
      },
    ],
  },
  // Фильтры объявлений
  {
    path: ROUTE_PROFILE_PRIORITY,
    component: PriorityComponent,
  },
  // Профиль - Избранное
  {
    path: ROUTE_FAVOURITES,
    component: FavouritesComponent,
    children: [
      {
        path: '',
        component: FavouritesAnnouncementsComponent,
      },
      {
        path: ROUTE_FAVOURITES_USERS,
        component: FavouritesUsersComponent,
      },
    ],
  },
  // Профиль - Настройки
  {
    path: ROUTE_SETTINGS,
    component: SettingsProfileComponent,
    children: [
      {
        path: '',
        component: PersonalDataComponent,
      },
      {
        path: ROUTE_PROFILE_CONTACTS,
        component: ProfileContactsComponent,
      },
      {
        path: ROUTE_PROFILE_SAFETY,
        component: SafetyComponent,
      },
    ],
  },
  {
    path: ROUTE_OPEN_CONTACTS,
    component: OpenContactsComponent,
  },
  {
    path: ROUTE_SUBSCRIPTION,
    component: SubscriptionComponent,
  },
];

const mainLayoutRoutes: Routes = [
  {
    path: ROUTE_HOME,
    component: HomeComponent,
  },
  {
    path: ROUTE_MY_ANNOUNCEMENTS,
    canActivate: [authGuard],
    component: MyAnnouncementsComponent,
  },
  {
    path: ROUTE_FAVOURITES,
    canActivate: [authGuard],
    component: FavouritesComponent,
  },
  {
    path: ROUTE_PRICE_ANALYSIS,
    canActivate: [authGuard],
    component: PriceAnalysisComponent,
  },
  {
    path: ROUTE_ANNOUNCEMENT_CREATE,
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './components/pages/announcement-create/announcement-create.component'
      ).then((m) => m.AnnouncementCreateComponent),
  },
  {
    path: ROUTE_PROFILE,
    canActivate: [authGuard],
    component: ProfileComponent,
    children: mainProfileRoutes,
  },
  {
    path: ROUTE_USER_CONTACTS + '/:userId',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/pages/user-contacts/user-contacts.component').then(
        (m) => m.UserContactsComponent,
      ),
    children: userContactsRoutes,
  },
  {
    path: ROUTE_ANNOUNCEMENT_EDIT + '/:slugAnnouncement',
    canActivate: [authGuard],
    loadComponent: () =>
      import(
        './components/pages/announcement-edit/announcement-edit.component'
      ).then((m) => m.AnnouncementEditComponent),
  },
  {
    path: ROUTE_ANNOUNCEMENT + '/:culture/:slug',
    loadComponent: () =>
      import('./components/pages/announcement/announcement.component').then(
        (m) => m.AnnouncementComponent,
      ),
  },
  {
    path: ROUTE_CATEGORY + '/:categoryCode',
    loadComponent: () =>
      import('./components/pages/categories/categories.component').then(
        (m) => m.CategoriesComponent,
      ),
  },
  {
    path: ROUTE_CATEGORY + '/:categoryCode/:cerealId',
    loadComponent: () =>
      import('./components/pages/categories/categories.component').then(
        (m) => m.CategoriesComponent,
      ),
  },
  {
    path: ROUTE_MAP + '/:categoryCode/:cerealId',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/pages/map/map.component').then(
        (m) => m.MapComponent,
      ),
  },
  {
    path: ROUTE_MAP + '/:categoryCode',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/pages/map/map.component').then(
        (m) => m.MapComponent,
      ),
  },
  {
    path: ROUTE_MAP,
    canActivate: [authGuard],
    loadComponent: () =>
      import('./components/pages/map/map.component').then(
        (m) => m.MapComponent,
      ),
  },
];

export const routes: Routes = [
  { path: ROUTE_CONTACTS, component: ContactsComponent },

  { path: ROUTE_AUTH, canActivate: [nonAuthGuard], component: AuthComponent },
  {
    path: ROUTE_REGISTRATION,
    canActivate: [nonAuthGuard],
    component: RegistrationComponent,
  },
  {
    path: ROUTE_REGISTRATION_CONFIRM,
    canActivate: [nonAuthGuard],
    component: EmailConfirmationComponent,
  },
  {
    path: ROUTE_PASSWORD_RECOVERY,
    canActivate: [nonAuthGuard],
    component: PasswordRecoveryComponent,
  },
  {
    path: ROUTE_HOME,
    component: MainLayoutComponent,
    children: mainLayoutRoutes,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

import { Component, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { switchMap } from 'rxjs';

import { MatIconModule } from '@angular/material/icon';

import { ButtonComponent } from '@components/ui/button/button.component';
import { ROUTE_MY_ANNOUNCEMENTS } from '@settings/routes';
import { UserFullNamePipe } from '@pipes/user-full-name.pipe';
import { TFormattedApiError } from '@utils/formattingApiError';
import {
  ERROR_MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE,
  availableImages,
  imageExtensions,
} from '@components/common/image-uploader/core/imageUploaderSettings';

import { FileService } from '@services/file.service';
import { ProfileService } from '@services/profile.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [RouterLink, ButtonComponent, MatIconModule, UserFullNamePipe],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  profileService = inject(ProfileService);
  notificationService = inject(NotificationService);
  fileService = inject(FileService);

  loading = signal(false);

  profile = this.profileService.profileData;
  myAnnouncementLink = ROUTE_MY_ANNOUNCEMENTS;
  acceptList = availableImages;

  onFileSelected = (event: Event) => {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const inputFile = target.files[0];

      const fileExtension = inputFile.name.split('.').pop();

      if (
        fileExtension !== undefined &&
        imageExtensions.indexOf(fileExtension.toLowerCase()) !== -1 &&
        inputFile.size > 0 &&
        inputFile.size <= MAX_IMAGE_SIZE
      ) {
        const formData = new FormData();
        formData.append('file', inputFile);

        this.loading.set(true);

        this.fileService
          .saveFile(formData)
          .pipe(
            switchMap((response) =>
              this.profileService.editProfileAvatar(response?.name || ''),
            ),
          )
          .subscribe({
            next: (response) => {
              this.loading.set(false);
              this.profileService.setProfileData(response);
            },
            error: (error: TFormattedApiError) => {
              this.loading.set(false);

              if (error.formattedErrorMessage)
                this.notificationService.error(error.formattedErrorMessage);
            },
          });
      } else {
        if (inputFile.size > MAX_IMAGE_SIZE) {
          this.notificationService.error(ERROR_MAX_IMAGE_SIZE);
        } else {
          if (inputFile.size <= 0) {
            this.notificationService.error('Файл не должен быть пустым');
          } else {
            this.notificationService.error('Не верный формат файла');
          }
        }
      }
    }
  };

  deleteAvatar() {
    this.loading.set(true);

    this.profileService.editProfileAvatar('').subscribe({
      next: (response) => {
        this.profileService.setProfileData(response);
        this.loading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.loading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }
}

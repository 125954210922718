import { Component, inject, input } from '@angular/core';

import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-button-clipboard',
  standalone: true,
  imports: [],
  templateUrl: './button-clipboard.component.html',
  styleUrl: './button-clipboard.component.scss',
})
export class ButtonClipboardComponent {
  value = input.required<string>();

  notificationService = inject(NotificationService);

  clipboardWriteValue() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.value())
        .then(() =>
          this.notificationService.success('Ссылка успешно скопирована'),
        )
        .catch(() =>
          this.notificationService.error(
            'При копировании ссылки произошла ошибка',
          ),
        );
    } else {
      console.error('Буфер обмена недоступен');
    }
  }
}

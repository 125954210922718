import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DeleteCardDialogComponent } from '@components/common/dialog/delete-card-dialog/delete-card-dialog.component';
import { ButtonAddCardComponent } from './button-add-card/button-add-card.component';

@Component({
  selector: 'app-payment-subscription',
  standalone: true,
  imports: [MatIcon, MatTooltipModule, ButtonAddCardComponent],
  templateUrl: './payment-subscription.component.html',
  styleUrl: './payment-subscription.component.scss',
})
export class PaymentSubscriptionComponent {
  readonly dialog = inject(MatDialog);

  // Открытие диалогового окна удаления карты
  openDeleteCardDialog() {
    const dialogRef = this.dialog.open(DeleteCardDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.deleteCardFunction();
    });
  }

  deleteCardFunction() {
    // todo удаление карты
  }

  selectedCardFunction() {
    // todo
  }
}

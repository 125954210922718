import { Component } from '@angular/core';

import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { SearchComponent } from '@components/ui/search/search.component';
import { SelectLocationComponent } from '@components/ui/select-location/select-location.component';
import { TitleComponent } from '@components/common/title/title.component';
import { ProfileSidebarComponent } from '../../profile-sidebar/profile-sidebar.component';

@Component({
  selector: 'app-mobile-profile',
  standalone: true,
  imports: [
    MobileHeaderComponent,
    SearchComponent,
    SelectLocationComponent,
    ProfileSidebarComponent,
    TitleComponent,
  ],
  templateUrl: './mobile-profile.component.html',
  styleUrl: './mobile-profile.component.scss',
})
export class MobileProfileComponent {}

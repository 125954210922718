import { Component, computed, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ProfileSidebarComponent } from './profile-sidebar/profile-sidebar.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { MobileProfileComponent } from './profilePages/mobile-profile/mobile-profile.component';

import { UIService } from '@services/ui.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    RouterOutlet,
    ProfileSidebarComponent,
    MobileProfileComponent,
    LoaderGlobalComponent,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  authService = inject(AuthService);
  uiService = inject(UIService);

  loading = computed(
    () => this.authService.loading() || this.authService.loadingAuthData(),
  );
  isMobile = this.uiService.isMobile;
}

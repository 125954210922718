import { differenceInDays } from 'date-fns';

export const changeGroupList = (startDate: Date, endDate: Date) => {
  const daysCount: number = differenceInDays(endDate, startDate);

  if (daysCount < 7) {
    return [{ id: 'day', name: 'Группировать по дням' }];
  }

  if (daysCount < 30) {
    return [
      { id: 'day', name: 'Группировать по дням' },
      { id: 'week', name: 'Группировать по неделям' },
    ];
  }

  if (daysCount < 30 * 3) {
    return [
      { id: 'day', name: 'Группировать по дням' },
      { id: 'week', name: 'Группировать по неделям' },
      { id: 'month', name: 'Группировать по месяцам' },
    ];
  }

  if (daysCount < 30 * 12) {
    return [
      { id: 'week', name: 'Группировать по неделям' },
      { id: 'month', name: 'Группировать по месяцам' },
    ];
  }

  return [{ id: 'month', name: 'Группировать по месяцам' }];
};

import { NgClass, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-confirmation-information',
  standalone: true,
  imports: [NgClass, NgIf],
  templateUrl: './confirmation-information.component.html',
  styleUrl: './confirmation-information.component.scss',
})
export class ConfirmationInformationComponent {
  variety = input.required<'success' | 'error'>();
  entity = input.required<string>();
}

<form
  name="create-password-form"
  (submit)="handleSubmit($event)"
  class="create-password-form"
>
  <div class="title mb-32">{{ title() }}</div>

  @if (
    !!apiError() ||
    (password.touched && !isPasswordValid()) ||
    (password_confirm.touched &&
      passwordCreateForm.password !== passwordCreateForm.password_confirm)
  ) {
    <app-info-block
      [description]="
        apiError()?.formattedErrorMessage ||
        'Введите совпадающие пароли, соответствующие указанным требованиям'
      "
      variety="error"
    />
  }

  <app-input-password
    name="password"
    id="password"
    #password="ngModel"
    [(ngModel)]="passwordCreateForm.password"
    [disabled]="disabled()"
    autocomplete="new-password"
    [error]="!!apiError() || (!!password.touched && !isPasswordValid())"
    (input)="changePassword(passwordCreateForm.password)"
    class="mb-16"
  />

  <div class="password_data">
    <div class="password_data_item">Сложность пароля:</div>
    <div class="password_data_item" [class.done]="passwordMinLength()">
      @if (!passwordMinLength()) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g opacity="0.8">
            <path
              d="M12 4L4 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 8.33333L6.33333 11.6667L13 5"
            stroke="#6F9305"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }

      Минимальное кол-во символов: 8
    </div>
    <div
      class="password_data_item"
      [class.done]="passwordHasDifferentLetters()"
    >
      @if (!passwordHasDifferentLetters()) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g opacity="0.8">
            <path
              d="M12 4L4 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 8.33333L6.33333 11.6667L13 5"
            stroke="#6F9305"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
      Содержит прописные и строчные латинские символы
    </div>
    <div class="password_data_item" [class.done]="passwordHasDigits()">
      @if (!passwordHasDigits()) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g opacity="0.8">
            <path
              d="M12 4L4 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 4L12 12"
              stroke="#878787"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 8.33333L6.33333 11.6667L13 5"
            stroke="#6F9305"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
      Содержит цифры
    </div>
  </div>

  <app-input-password
    name="password_confirm"
    id="password_confirm"
    #password_confirm="ngModel"
    autocomplete="new-password"
    [(ngModel)]="passwordCreateForm.password_confirm"
    label="Повтор пароля"
    [requiredLabel]="true"
    placeholder="Повтор пароля"
    [disabled]="disabled()"
    [error]="
      !!apiError() ||
      (!!password_confirm.touched &&
        passwordCreateForm.password_confirm !== passwordCreateForm.password)
    "
    class="mb-16"
  />

  <app-button
    variety="secondary"
    width="stretch"
    type="submit"
    class="submit-button mt-24 mb-24"
    [disabled]="
      !isPasswordValid() ||
      passwordCreateForm.password_confirm !== passwordCreateForm.password
    "
    >Создать пароль</app-button
  >
</form>

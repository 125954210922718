export type TPromoItemsList = {
  id: number;
  name: string;
}[];

export const promoItemsList: TPromoItemsList = [
  { id: 1, name: 'Для переработчиков' },
  { id: 4, name: 'Для фермеров' },
  { id: 7, name: 'Для трейдеров' },

  { id: 2, name: 'Доступ в раздел «Аналитика цен»' },
  { id: 5, name: 'Для переработчиков' },
  { id: 9, name: 'Предложение связаться – 50 шт ' },

  { id: 3, name: 'Доступ в раздел «Аналитика цен»' },
  { id: 6, name: 'Открытие контактов – 50 шт ' },
  { id: 8, name: 'Предложение связаться – 50 шт ' },
];

import { DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { catchError, of, switchMap, tap } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { ActivateSubscriptionDialogComponent } from '@components/common/dialog/activate-subscription-dialog/activate-subscription-dialog.component';
import { CancelSubscriptionDialogComponent } from '@components/common/dialog/cancel-subscription-dialog/cancel-subscription-dialog.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { TitleComponent } from '@components/common/title/title.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { DeclensePipe } from '@pipes/declense.pipe';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_PROFILE } from '@settings/routes';

import { NotificationService } from '@services/notification.service';
import { SubscriptionService } from '@services/subscription.service';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-with-subscription',
  standalone: true,
  imports: [
    ButtonComponent,
    DeclensePipe,
    DecimalPipe,
    DatePipe,
    NgIf,
    TitleComponent,
    MobileHeaderComponent,
    LinkBackComponent,
  ],
  templateUrl: './with-subscription.component.html',
  styleUrl: './with-subscription.component.scss',
})
export class WithSubscriptionComponent {
  subscriptionService = inject(SubscriptionService);
  notificationService = inject(NotificationService);
  readonly dialog = inject(MatDialog);
  uiService = inject(UIService);

  isMobile = this.uiService.isMobile;

  loading = signal<boolean>(false);

  routeProfile = `/${ROUTE_PROFILE}`;

  subscription = this.subscriptionService.subscription;
  openContactsCount = computed(
    () =>
      Number(this.subscription()?.subscription_openings_left) +
      Number(this.subscription()?.contact_package_openings_left),
  );

  // Открытие диалогового окна активации подписки
  openActivateDialog() {
    const dialogRef = this.dialog.open(ActivateSubscriptionDialogComponent, {
      data: { price: this.subscription()?.subscription_price },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.activateSubscription();
    });
  }

  activateSubscription() {
    this.loading.set(true);

    this.subscriptionService
      .activateSubscription()
      .pipe(
        switchMap(() => {
          return this.subscriptionService.loadSubscription();
        }),
        tap((response) => {
          this.loading.set(false);

          this.subscriptionService.setSubscription(response);
        }),
        catchError((error: TFormattedApiError) => {
          this.notificationService.error(error.formattedErrorMessage);

          this.loading.set(false);

          return of(null);
        }),
      )
      .subscribe();
  }

  // Открытие диалогового окна отмены подписки
  openCancelDialog() {
    const dialogRef = this.dialog.open(CancelSubscriptionDialogComponent, {
      data: { date: this.subscription()?.subscription_next_period?.date },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.cancelSubscription();
    });
  }

  cancelSubscription() {
    this.loading.set(true);

    this.subscriptionService
      .cancelSubscription()
      .pipe(
        switchMap(() => {
          return this.subscriptionService.loadSubscription();
        }),
        tap((response) => {
          this.loading.set(false);

          this.subscriptionService.setSubscription(response);
        }),
        catchError((error: TFormattedApiError) => {
          this.notificationService.error(error.formattedErrorMessage);
          this.loading.set(false);

          return of(null);
        }),
      )
      .subscribe();
  }
}

<div class="my-announcements-card" [class.last]="isLastCard()">
  @if (announcement().status.value === "draft") {
    <app-my-announcements-image
      [announcement]="announcement()"
      [class.disabled-announcement]="variety() === 'disabled'"
    />
  } @else {
    <a
      [routerLink]="[
        '/',
        ROUTE_ANNOUNCEMENT,
        announcement().category.code,
        announcement().slug,
      ]"
      [queryParams]="{ source: source() }"
      class="announcement-image-link"
    >
      <app-my-announcements-image
        [announcement]="announcement()"
        [class.disabled-announcement]="variety() === 'disabled'"
      />
    </a>
  }

  <app-my-announcements-description
    [announcement]="announcement()"
    [status]="announcement().status.value"
    [variety]="variety()"
    [class.disabled-announcement]="variety() === 'disabled'"
    [source]="source()"
  />

  <div
    class="stats-block"
    [class.disabled-announcement]="variety() === 'disabled'"
    [class.draft]="variety() === 'draft'"
  >
    @if (variety() !== "draft") {
      <div matTooltip="Количество просмотров" class="stats-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z"
            fill="#050609"
            stroke="#050609"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
            fill="#050609"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        @if (variety() === "active") {
          {{ announcement().views?.all_time | number: "1.0-2" : "ru-Ru" }}
          (+{{ announcement().views?.today | number: "1.0-2" : "ru-Ru" }})
        } @else {
          {{ announcement().views?.all_time | number: "1.0-2" : "ru-Ru" }}
        }
      </div>
      <div matTooltip="Количество открытий контактов" class="stats-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
            fill="#050609"
          />
        </svg>
        {{ announcement().open_contacts | number: "1.0-2" : "ru-Ru" }}
      </div>
      <div matTooltip="Количество занесений в избранное" class="stats-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.6 13.5556C18.792 12.42 20 11.0589 20 9.27778C20 8.14324 19.5364 7.05517 18.7113 6.25293C17.8861 5.45069 16.767 5 15.6 5C14.192 5 13.2 5.38889 12 6.55556C10.8 5.38889 9.808 5 8.4 5C7.23305 5 6.11389 5.45069 5.28873 6.25293C4.46357 7.05517 4 8.14324 4 9.27778C4 11.0667 5.2 12.4278 6.4 13.5556L12 19L17.6 13.5556Z"
            fill="#6F9305"
            stroke="#6F9305"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ announcement().favorites | number: "1.0-2" : "ru-Ru" }}
      </div>
    }
  </div>

  <!-- Кнопки для активных объявлений -->
  <div *ngIf="variety() === 'active'" class="buttons-block">
    <app-button
      type="button"
      [routerLink]="['/', ROUTE_ANNOUNCEMENT_EDIT, announcement().slug]"
      width="stretch"
      [disabled]="loading()"
      class="button-element"
      >Редактировать</app-button
    >
    <app-button
      type="button"
      width="stretch"
      variety="grey"
      (pressed)="openDisabledDialog()"
      [disabled]="loading()"
      class="button-element"
      >Снять с публикации</app-button
    >
  </div>

  <!-- Кнопки для неактивных объявлений -->
  <div
    *ngIf="variety() === 'disabled'"
    class="buttons-block disabled-announcements"
  >
    <!-- Объявление успешно прошло модерацию -->
    <app-button
      type="button"
      variety="secondary"
      width="stretch"
      [disabled]="loading()"
      (pressed)="announcementActive()"
      *ngIf="announcement().moderation_condition?.value === 'passed'"
      class="button-element"
      >Опубликовать</app-button
    >
    <!-- Объявление на модерации -->
    <app-info-block-announcement
      variety="moderation"
      title="Объявление на модерации"
      *ngIf="announcement().moderation_condition?.value === 'moderation'"
      class="moderation-message"
    />

    <!-- Объявление отклонено -->
    <app-info-block-announcement
      variety="rejected"
      title="Объявление отклонено"
      [description]="announcement().rejection_reason || ''"
      *ngIf="announcement().moderation_condition?.value === 'rejected'"
      class="rejected-message"
    />

    <div class="buttons-row">
      <app-button
        type="button"
        [routerLink]="['/', ROUTE_ANNOUNCEMENT_EDIT, announcement().slug]"
        width="stretch"
        [narrowPadding]="true"
        [disabled]="loading()"
        class="button-element"
        >Редактировать</app-button
      >
      <app-button
        type="button"
        width="stretch"
        variety="grey"
        [narrowPadding]="true"
        (pressed)="openDeleteDialog('disabled')"
        [disabled]="loading()"
        class="button-element"
        >Удалить</app-button
      >
    </div>
  </div>

  <!-- Кнопки для черновиков объявлений -->
  <div *ngIf="variety() === 'draft'" class="buttons-block">
    <app-button
      type="button"
      [routerLink]="['/', ROUTE_ANNOUNCEMENT_EDIT, announcement().slug]"
      width="stretch"
      [disabled]="loading()"
      class="button-element"
      >Редактировать</app-button
    >
    <app-button
      type="button"
      width="stretch"
      variety="grey"
      (pressed)="openDeleteDialog('draft')"
      [disabled]="loading()"
      class="button-element"
      >Удалить</app-button
    >
  </div>
</div>

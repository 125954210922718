import { Component } from '@angular/core';
import { TransparentLabelComponent } from './transparent-label/transparent-label.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';

@Component({
  selector: 'app-advertising-big-banner',
  standalone: true,
  imports: [TransparentLabelComponent, ButtonLinkComponent],
  templateUrl: './advertising-big-banner.component.html',
  styleUrl: './advertising-big-banner.component.scss',
})
export class AdvertisingBigBannerComponent {
  // todo Добавить сслыку
  trialLink = '/';
}

@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title>Избранное</app-title>
  </app-mobile-header>
} @else {
  <app-title>Избранное</app-title>
}

<app-tabs id="tabs" [tabList]="tabList" [countsList]="favouritesCounts()" />

<router-outlet />

import { Component, input } from '@angular/core';
import { NgIf } from '@angular/common';

import { TRequisites } from '../core/contactsTypes';

@Component({
  selector: 'app-requisites',
  standalone: true,
  imports: [NgIf],
  templateUrl: './requisites.component.html',
  styleUrl: './requisites.component.scss',
})
export class RequisitesComponent {
  requisites = input.required<TRequisites | undefined>();
}

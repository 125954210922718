<button type="button" class="button-card-add">
  <svg
    matTooltip="При привязке c карты снимается 10 руб и сразу же возвращается обратно"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    class="info-icon"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
      fill="#DEDEDE"
    />
    <path
      d="M12 16V12"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 8H11.991"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M3.5 10H21.5M7.5 15H7.51M11.5 15H13.5M3.5 8C3.5 7.20435 3.81607 6.44129 4.37868 5.87868C4.94129 5.31607 5.70435 5 6.5 5H18.5C19.2956 5 20.0587 5.31607 20.6213 5.87868C21.1839 6.44129 21.5 7.20435 21.5 8V16C21.5 16.7956 21.1839 17.5587 20.6213 18.1213C20.0587 18.6839 19.2956 19 18.5 19H6.5C5.70435 19 4.94129 18.6839 4.37868 18.1213C3.81607 17.5587 3.5 16.7956 3.5 16V8Z"
      stroke="#050609"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <div>Привязать карту</div>
</button>

import { DatePipe } from '@angular/common';
import { isNaN } from 'lodash-es';

export const formattingDateForRequest = (currentDate?: Date | null) => {
  let newDate;

  if (currentDate) newDate = currentDate;
  else newDate = new Date();

  newDate = new DatePipe('ru-Ru').transform(currentDate, 'yyyy-MM-dd');

  return newDate;
};

export const formattingDateForStore = (currentDate?: string | null): string => {
  if (!currentDate || isNaN(new Date(currentDate))) return '-';

  return new DatePipe('ru-Ru').transform(currentDate, 'dd.MM.yy') || '';
};

export const getPrevDate = (currentDate?: Date | null): Date => {
  let newDate;

  if (currentDate) newDate = currentDate;
  else newDate = new Date();

  newDate.setMonth(newDate.getMonth() - 1);

  return newDate;
};

import { DatePipe } from '@angular/common';
import { Component, inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { ButtonComponent } from '@components/ui/button/button.component';

@Component({
  selector: 'app-cancel-subscription-dialog',
  standalone: true,
  imports: [ButtonComponent, MatDialogModule, MatIcon, DatePipe],
  templateUrl: './cancel-subscription-dialog.component.html',
  styleUrl: '../dialog.component.scss',
})
export class CancelSubscriptionDialogComponent {
  data = inject(MAT_DIALOG_DATA);
}

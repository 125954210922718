@if (error()) {
  <app-info-block
    variety="error"
    [description]="error() || 'Неизвестная ошибка'"
  />
}

<app-data-wrapper
  [isLoading]="isLoading()"
  [isEmpty]="!isLoading() && activeAnnouncements().length < 1"
  emptyDescription="Список активных объявлений пуст"
>
  <div class="user-contacts-announcements-list">
    @for (announcement of activeAnnouncements(); track announcement.slug) {
      <app-announcement-card
        source="user-active"
        [announcement]="announcement"
        (addingToFavourite)="changeAnnouncementIsFavourite($event)"
        [isFavouritesLoading]="isFavouritesLoading()"
      />
    }
  </div>

  @if (isInfiniteLoading()) {
    <app-loader-global class="infinite-loader" [isInfinite]="true" />
  }
</app-data-wrapper>

<form [formGroup]="analyticsFilterForm">
  <div class="view-by-block">
    <button
      type="button"
      (click)="changeViewBy('trader')"
      class="view-by left"
      [class.active]="viewBy() === 'trader'"
    >
      Цены объявлений
    </button>
    <button
      type="button"
      (click)="changeViewBy('recycler')"
      class="view-by middle"
      [class.active]="viewBy() === 'recycler'"
    >
      Цены от зернообработчиков
    </button>
    <button
      type="button"
      (click)="changeViewBy('mixed')"
      class="view-by right"
      [class.active]="viewBy() === 'mixed'"
    >
      Сравнить обе рыночных цены
    </button>
  </div>

  <div class="subtitle">Параметры графика</div>

  @if (error()) {
    <app-info-block
      variety="error"
      [description]="error() || 'Неизвестная ошибка'"
      class="announcement-form-error-block"
    />
  }

  <div class="form-wrapper">
    <div class="price-analysis-filter-item">
      <label for="category_code">Категория:</label>
      <mat-select
        #categorySelect="matSelect"
        id="category_code"
        name="category_code"
        formControlName="category_code"
        class="select short"
        [class.opened]="categorySelect.panelOpen"
        [class.error]=""
        panelClass="select-list"
        placeholder="Категория"
      >
        @for (category of categoriesList(); track category.code) {
          <mat-option [value]="category.code" class="select-list-option">{{
            category.label
          }}</mat-option>
        }
      </mat-select>
    </div>

    <div class="price-analysis-filter-item">
      <label for="cereal_id">Зерновая культура:</label>
      <mat-select
        #cerealSelect="matSelect"
        id="cereal_id"
        name="cereal_id"
        formControlName="category_cereal_classification_data"
        (closed)="resetCerealSearch()"
        class="select long"
        [class.opened]="cerealSelect.panelOpen"
        [class.error]="
          analyticsFilterForm.controls['category_cereal_classification_data']
            .errors &&
          analyticsFilterForm.controls['category_cereal_classification_data']
            .touched &&
          !cerealSelect.panelOpen
        "
        [class.disabled]="cerealsList().length < 1"
        panelClass="select-list"
        placeholder="Зерновая культура"
        [typeaheadDebounceInterval]="500"
        multiple
      >
        <mat-select-trigger>
          {{ cerealSelectedValues() }}
        </mat-select-trigger>
        <app-search
          #cerealSearch
          placeholder="Поиск по культурам"
          variety="select"
          (searchText)="onSearchCereals($event)"
        />

        <hr class="analytics-hr" />

        @if (filterCerealsList().length > 0) {
          @for (
            cereal of filterCerealsList();
            let index = $index;
            track cereal.cereal_id
          ) {
            <!-- В культуре есть классификации -->
            <mat-accordion
              *ngIf="
                cereal.classifications && cereal.classifications.length > 0
              "
            >
              <mat-optgroup class="select-list-option">
                <mat-expansion-panel
                  class="analytics-cereals-panel"
                  expanded="true"
                >
                  <mat-expansion-panel-header class="analytics-cereals-header">
                    <!-- todo на случай реализации выбора всей группы -->
                    <!-- <mat-option [value]="cereal" class="select-list-option">
                      <mat-checkbox class="priority">
                        <div class="checkbox-analytics">
                          {{ cereal.label }}
                        </div>
                      </mat-checkbox>
                    </mat-option> -->
                    {{ cereal.label }}
                  </mat-expansion-panel-header>

                  @if (cereal.classifications) {
                    @for (
                      classification of cereal.classifications;
                      track classification.classification_id
                    ) {
                      <mat-option
                        [value]="classification"
                        class="select-list-option multi-with-checkbox"
                      >
                        <!-- <mat-checkbox class="priority">
                          <div class="checkbox-analytics">
                            {{ classification.label }}
                          </div>
                        </mat-checkbox> -->
                        {{ classification.label }}
                      </mat-option>
                    }
                  }
                  <div
                    *ngIf="index !== filterCerealsList().length - 1"
                    class="cereals-divider"
                  ></div>
                </mat-expansion-panel>
              </mat-optgroup>
            </mat-accordion>

            <!-- В культуре нет классификаций -->
            <div
              *ngIf="
                !cereal.classifications || cereal.classifications.length < 1
              "
              class="item-wrapper cereal"
            >
              <mat-option
                [value]="cereal"
                class="select-list-option multi-with-checkbox"
                >{{ cereal.label }}</mat-option
              >
            </div>
          }
        } @else {
          <div class="empty-list">Культуры не найдены</div>
        }
      </mat-select>
    </div>

    <div class="price-analysis-filter-item">
      <label for="regions">Регионы:</label>
      <mat-select
        #regionsSelect="matSelect"
        formControlName="address_data"
        id="regions"
        name="regions"
        (closed)="resetAddressSearch()"
        class="select long"
        [class.opened]="regionsSelect.panelOpen"
        [class.error]="
          analyticsFilterForm.controls['address_data'].errors &&
          analyticsFilterForm.controls['address_data'].touched &&
          !cerealSelect.panelOpen
        "
        panelClass="select-list analytics"
        placeholder="Регионы"
        [typeaheadDebounceInterval]="500"
        multiple
      >
        <app-search
          #addressSearch
          placeholder="Поиск по регионам"
          variety="select"
          (searchText)="onSearchAddress($event)"
        />

        <hr class="analytics-hr" />

        @if (filterAddressList().length > 0) {
          @for (address of filterAddressList(); let id = $index; track id) {
            <mat-option
              [value]="address"
              class="select-list-option multi-with-checkbox"
            >
              {{ address.label }}</mat-option
            >
          }
        } @else {
          <div class="empty-list">Адрес не найден</div>
        }
      </mat-select>
    </div>

    <div class="price-analysis-filter-item">
      <label for="cereal_id">Группировка:</label>
      <mat-select
        #groupBySelect="matSelect"
        id="group_by"
        name="group_by"
        formControlName="group_by"
        class="select short"
        [class.opened]="groupBySelect.panelOpen"
        panelClass="select-list group-by"
        placeholder="Группировка"
      >
        @for (group of priceAnalysisGroupingList(); track group.id) {
          <mat-option [value]="group.id" class="select-list-option">{{
            group.name
          }}</mat-option>
        }
      </mat-select>
    </div>

    <div class="price-analysis-filter-item">
      <label for="cereal_id">Период:</label>
      <mat-form-field
        class="datapicker"
        [class.error]="
          ((analyticsFilterForm.controls['start'].errors &&
            analyticsFilterForm.controls['start'].touched) ||
            (analyticsFilterForm.controls['end'].errors &&
              analyticsFilterForm.controls['end'].touched)) &&
          !picker.opened
        "
      >
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="дд.мм.гггг"
          />
          <input matEndDate formControlName="end" placeholder="дд.мм.гггг" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <mat-radio-group
      id="type"
      name="type"
      formControlName="type"
      aria-label="type"
      class="type-block radio-label"
    >
      <mat-radio-button id="sell" name="sell" value="sell"
        >ПРОДАЖА
      </mat-radio-button>
      <mat-radio-button id="buy" name="buy" value="buy"
        >ПОКУПКА</mat-radio-button
      >
    </mat-radio-group>

    @if (analyticsFilterForm.value.address_data.length > 0) {
      <div class="selected-addresses">
        @for (
          address of analyticsFilterForm.value.address_data;
          track address
        ) {
          <div class="address">
            {{ address.label }}
            <mat-icon (click)="deleteAddress(address)" class="close"
              >close</mat-icon
            >
          </div>
        }
      </div>
    }

    <div class="buttons-block">
      <app-button
        type="submit"
        variety="secondary"
        (pressed)="loadChartData()"
        [disabled]="loading() || analyticsFilterForm.invalid"
        >Показать график</app-button
      >
      <app-button variety="grey" (click)="resetForm()">Сбросить все</app-button>
    </div>
  </div>
</form>

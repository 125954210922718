// export const PHONE_PATTERN = /^\+7 [0-9]{3} [0-9]{3} [0-9]{4}/;

// export const PHONE_PATTERN = /^7[0-9]{10}$/;

export const PHONE_PATTERN = /^[0-9]{10}$/;

export const PASSWORD_PATTERN = /^(?![\d+_@.-]+$)[a-zA-Z0-9]*$/;

export const USER_NAME_PATTERN =
  /^[А-яё]{1,}([-– ][А-яё]{1,}([ ]*))?$|^[А-яё]{1,}([ ]*)$/;

export const ONLY_LETTERS_PATTERN = /^[А-яё]{0,255}$/;

export const INN_PATTERN = /^(\d{10}|\d{12})$/;

export const ONLY_DECIMAL_DIGITS_PATTERN = /^\d*$/;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

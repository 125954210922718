import { Component, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';

import { ButtonComponent } from '@components/ui/button/button.component';
import { InputPhoneComponent } from '@components/ui/input-phone/input-phone.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { RegistrationService } from '../../core/registration.service';
import { PHONE_PATTERN } from '@settings/validationSettings';
import { ErrorComponent } from '@components/common/error/error.component';
import { TSetPhoneRequest } from '../../core/registrationTypes';
import { LabelComponent } from '@components/ui/label/label.component';
import { BackLinkComponent } from '@components/common/back-link/back-link.component';
import { ROUTE_AUTH } from '@settings/routes';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-set-phone',
  standalone: true,
  imports: [
    LabelComponent,
    InputPhoneComponent,
    ButtonComponent,
    LinkComponent,
    FormsModule,
    ErrorComponent,
    BackLinkComponent,
    RecaptchaModule,
  ],
  templateUrl: './set-phone.component.html',
  styleUrl: './set-phone.component.scss',
})
export class SetPhoneComponent {
  captcha = viewChild<RecaptchaComponent>('captcha');

  error = this.registrationService.error;
  loading = this.registrationService.loading;

  key = environment.captcha_key;

  setPhoneForm: TSetPhoneRequest = {
    phone_number: this.registrationService.phoneNumber().slice(2, 12),
    recaptcha: null,
  };

  phonePattern = PHONE_PATTERN;
  authRoute = `/${ROUTE_AUTH}`;

  constructor(private registrationService: RegistrationService) {}

  resolved(captchaResponse: string | null) {
    this.setPhoneForm.recaptcha = captchaResponse;
  }

  setPhone(event: Event) {
    event.preventDefault();

    this.registrationService
      .setPhoneNumber({
        phone_number: '+7' + this.setPhoneForm.phone_number,
        recaptcha: this.setPhoneForm.recaptcha,
      })
      .subscribe({
        next: () => {
          this.captcha()?.reset();
        },
        error: () => {
          this.captcha()?.reset();
        },
      });
  }
}

import { TMenuItems } from '../models/commonTypes';
import {
  ROUTE_CONTACTS,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_PRICE_ANALYSIS,
  ROUTE_PROFILE,
} from './routes';

export const footerMenuListOpen: TMenuItems = [
  {
    id: 200,
    name: 'Аналитика цен',
    link: `/${ROUTE_PRICE_ANALYSIS}`,
  },
  {
    id: 201,
    name: 'Контакты',
    link: `/${ROUTE_CONTACTS}`,
  },
];

export const footerMenuList: TMenuItems = [
  {
    id: 200,
    name: 'Аналитика цен',
    link: `/${ROUTE_PRICE_ANALYSIS}`,
  },
  {
    id: 201,
    name: 'Мои объявления',
    link: `/${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`,
  },
  {
    id: 202,
    name: 'Профиль',
    link: `/${ROUTE_PROFILE}`,
  },
  {
    id: 203,
    name: 'Контакты',
    link: `/${ROUTE_CONTACTS}`,
  },
];

import { isEmpty } from 'lodash-es';

export type TApiError = {
  code: number;
  code_status?: string;
  message?: string;
  error_data?: Record<string, string> | string;
};

export type TFormattedApiError = {
  code: number;
  formattedErrorMessage: string;

  code_status?: string;
  message?: string;
  error_data?: Record<string, string> | string;
};

export const formattingApiError = (
  error: TApiError,
  url: string,
): TFormattedApiError => ({
  code: error.code,
  code_status: error.code_status,
  error_data: error.error_data,

  formattedErrorMessage: formattingErrorMessage(error, url),
});

const formattingErrorMessage = (error: TApiError, url: string): string => {
  console.error('Error url: ', url);
  if (!error.code) return 'Ошибка доступа к серверу';

  if (error.message) {
    if (error.error_data && !isEmpty(error.error_data)) {
      // Убираем из текста ошибки фразу "Ошибка валидации"
      if (error.code === 460) {
        return `${Object.values(error.error_data).join('\n')}`;
      } else {
        return `${error.message}!\n\n${Object.values(error.error_data).join(
          '\n',
        )}`;
      }
    } else return error.message;
  }

  return 'Неизвестная ошибка';
};

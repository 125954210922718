import { Component } from '@angular/core';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';

@Component({
  selector: 'app-advertising-small-banner',
  standalone: true,
  imports: [ButtonLinkComponent],
  templateUrl: './advertising-small-banner.component.html',
  styleUrl: './advertising-small-banner.component.scss',
})
export class AdvertisingSmallBannerComponent {
  // todo Добавить ссылку
  trialLink = '/';
}

import { TMenuItemsString } from '@models/commonTypes';
import {
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_MY_ANNOUNCEMENTS_DISABLE,
  ROUTE_MY_ANNOUNCEMENTS_DRAFT,
  ROUTE_PROFILE,
} from '@settings/routes';

export const myAnnouncementsMenuList: TMenuItemsString = [
  {
    id: 'my-announcement-profile-active',
    link: `/${ROUTE_PROFILE}/${ROUTE_MY_ANNOUNCEMENTS}`,
    name: 'Активные',
  },
  {
    id: 'my-announcement-profile-disable',
    link: ROUTE_MY_ANNOUNCEMENTS_DISABLE,
    name: 'Неактивные',
  },
  {
    id: 'my-announcement-profile-draft',
    link: ROUTE_MY_ANNOUNCEMENTS_DRAFT,
    name: 'Черновики',
  },
];

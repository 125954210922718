<app-external-layout>
  <div class="full-screen-form">
    @if (!loading()) {
      @if (!error()) {
        <div class="title mb-32">Успешная регистрация</div>
        <app-info-block
          variety="success"
          description="Вы успешно зарегистрировались в сервисе"
        />
        <app-link [link]="authRoute" description="Войти в аккаунт" />
      } @else {
        <div class="title mb-32">Ошибка подтверждения регистрации</div>
        <app-info-block
          variety="error"
          [description]="error() || 'Неизвестная ошибка'"
        />
        <div class="link-block">
          Есть аккаунт?
          <app-link [link]="authRoute" description="Войти" />
        </div>
      }
    } @else {
      <app-loader class="registration-loader" />
    }
  </div>
</app-external-layout>

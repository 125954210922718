import { Component, afterNextRender, inject, signal } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { catchError, of, switchMap, tap } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { BuyPackageDialogComponent } from '@components/common/dialog/buy-package-dialog/buy-package-dialog.component';
import { LoaderGlobalComponent } from '@components/common/loader-global/loader-global.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { DeclensePipe } from '@pipes/declense.pipe';
import { TContactPackage } from '../core/subscriptionTypes';

import { NotificationService } from '@services/notification.service';
import { SubscriptionService } from '@services/subscription.service';

@Component({
  selector: 'app-contact-packages',
  standalone: true,
  imports: [LoaderGlobalComponent, DeclensePipe, DecimalPipe],
  templateUrl: './contact-packages.component.html',
  styleUrl: './contact-packages.component.scss',
})
export class ContactPackagesComponent {
  subscriptionService = inject(SubscriptionService);
  notificationService = inject(NotificationService);
  readonly dialog = inject(MatDialog);

  contactPackages = this.subscriptionService.contactPackages;
  loading = signal(true);
  disabled = signal(false);

  constructor() {
    afterNextRender(() => {
      this.loading.set(true);

      this.subscriptionService.loadContactPackages().subscribe({
        next: (response) => {
          this.subscriptionService.setContactPackages(response);
          this.loading.set(false);
        },
        error: (error: TFormattedApiError) => {
          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
          this.loading.set(false);
        },
      });
    });
  }

  // Открытие диалогового окна покупки пакета открытий контактов
  openBuyPackageDialog(contactPackage: TContactPackage) {
    const dialogRef = this.dialog.open(BuyPackageDialogComponent, {
      data: { contactPackage },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.buyPackageDialog(contactPackage.id);
    });
  }

  buyPackageDialog(id: number) {
    this.disabled.set(true);

    this.subscriptionService
      .buyContactPackage(id)
      .pipe(
        switchMap(() => {
          return this.subscriptionService.loadSubscription();
        }),
        tap((response) => {
          this.disabled.set(false);

          this.subscriptionService.setSubscription(response);
        }),
        catchError((error: TFormattedApiError) => {
          this.notificationService.error(error.formattedErrorMessage);

          this.disabled.set(false);

          return of(null);
        }),
      )
      .subscribe();
  }
}

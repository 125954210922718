import { Component, inject, signal } from '@angular/core';
import { catchError, of, switchMap, tap } from 'rxjs';

import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { TitleComponent } from '@components/common/title/title.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { LinkBackComponent } from '@components/ui/link-back/link-back.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_PROFILE } from '@settings/routes';

import { SubscriptionService } from '@services/subscription.service';
import { UIService } from '@services/ui.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-without-subscription',
  standalone: true,
  imports: [
    TitleComponent,
    ButtonComponent,
    MobileHeaderComponent,
    LinkBackComponent,
  ],
  templateUrl: './without-subscription.component.html',
  styleUrl: './without-subscription.component.scss',
})
export class WithoutSubscriptionComponent {
  subscriptionService = inject(SubscriptionService);
  notificationService = inject(NotificationService);
  uiService = inject(UIService);

  isMobile = this.uiService.isMobile;

  loading = signal(false);

  routeProfile = `/${ROUTE_PROFILE}`;

  activationDemo() {
    this.loading.set(true);

    this.subscriptionService
      .activateDemo()
      .pipe(
        switchMap(() => {
          return this.subscriptionService.loadSubscription();
        }),
        tap((response) => {
          this.loading.set(false);

          this.subscriptionService.setSubscription(response);
        }),
        catchError((error: TFormattedApiError) => {
          this.notificationService.error(error.formattedErrorMessage);
          this.loading.set(false);

          return of(null);
        }),
      )
      .subscribe();
  }
}

<div class="mobile-footer">
  <a
    [routerLink]="[homeLink]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    class="mobile-footer-link"
    [class.home]="isHomePage()"
  >
    <div class="image-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="20"
        viewBox="0 0 28 20"
        fill="none"
        class="filled"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.79054 3.95637C10.2956 3.32483 11.9085 3 13.5375 3C15.1664 3 16.7794 3.32483 18.2844 3.95637C19.7892 4.58764 21.1567 5.51299 22.3085 6.67942C23.4603 7.84585 24.374 9.23085 24.9973 10.7548C25.6209 12.279 25.9416 13.9125 25.9416 15.5622V16.5644H9.24142L9.32439 15.4845C9.52014 12.9369 10.0873 10.788 11.2649 9.26215C12.4871 7.67835 14.2606 6.89908 16.5334 6.89908H17.4913L17.5225 7.86864C17.5561 8.91123 17.2785 10.4456 16.2816 11.6829C15.3182 12.8784 13.7828 13.6733 11.5688 13.5929C11.5078 13.8985 11.4547 14.2206 11.4094 14.56H23.9149C23.8168 13.5171 23.5657 12.4932 23.1685 11.522C22.6447 10.241 21.8769 9.07708 20.9087 8.09682C19.9407 7.11655 18.7914 6.33866 17.5266 5.80816C16.2618 5.27767 14.9061 5.00446 13.5372 5.00446C12.1683 5.00446 10.8126 5.27767 9.5478 5.80816C8.28298 6.33866 7.13367 7.11628 6.16573 8.09682C5.1978 9.07735 4.42969 10.241 3.90587 11.522C3.38124 12.8048 2.90893 15.1802 2.90893 16.5644H0.929688C0.929688 14.9125 1.45459 12.2771 2.07738 10.7548C2.7007 9.23057 3.61441 7.84585 4.76617 6.67942C5.91792 5.51299 7.28577 4.58764 8.79054 3.95637ZM12.1832 11.587C13.5133 11.5176 14.2842 10.9917 14.7481 10.4159C15.0948 9.98567 15.3101 9.4766 15.4283 8.98949C14.2568 9.18581 13.4301 9.71164 12.8244 10.4964C12.5844 10.8072 12.3705 11.1702 12.1832 11.587Z"
          fill="#878787"
        />
      </svg>
    </div>
    <div class="label">Объявления</div>
  </a>
  <a
    [routerLink]="[myAnnouncementsLink]"
    routerLinkActive="active"
    class="mobile-footer-link"
  >
    <div class="image-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        class="stroked"
      >
        <path
          d="M16.625 8.75H4.375M4.375 5.25H16.625M12.25 12.25H4.375M4.375 15.75H9.625M15.75 13.125V18.375M13.125 15.75H18.375"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="label">Мои объявления</div>
  </a>
  <a
    [routerLink]="[favouritesLink]"
    routerLinkActive="active"
    class="mobile-footer-link"
  >
    <div class="image-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        class="stroked"
      >
        <path
          d="M16.625 12.25C17.9288 10.9725 19.25 9.44125 19.25 7.4375C19.25 6.16115 18.743 4.93707 17.8405 4.03455C16.9379 3.13203 15.7139 2.625 14.4375 2.625C12.8975 2.625 11.8125 3.0625 10.5 4.375C9.1875 3.0625 8.1025 2.625 6.5625 2.625C5.28615 2.625 4.06207 3.13203 3.15955 4.03455C2.25703 4.93707 1.75 6.16115 1.75 7.4375C1.75 9.45 3.0625 10.9812 4.375 12.25L10.5 18.375L16.625 12.25Z"
          stroke="#878787"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="label">Избранное</div>
  </a>

  <app-mobile-menu (logOut)="logOut()"></app-mobile-menu>
</div>

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ROUTE_HOME } from '@settings/routes';
import { AuthService } from '@services/auth.service';

export const nonAuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuth()) return true;

  router.navigate([ROUTE_HOME]);
  return false;
};

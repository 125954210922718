<div class="header">
  <div class="header-content">
    <app-logo-link class="header-logo-block" />

    <div class="header-management-block">
      <div class="header-navigation-block">
        <div class="navigation-block-menu">
          @for (item of menuList(); track item) {
            <app-menu-link
              [menuItem]="item"
              variety="black"
              [adaptiveText]="false"
            ></app-menu-link>
          }
        </div>

        <div class="navigation-block-buttons">
          @if (true === isAuth()) {
            <app-profile-button />
          }
          @if (false === isAuth()) {
            <app-button-link [link]="authLink"
              >Вход / Регистрация
            </app-button-link>
          }
          <app-icon-button-link
            [link]="favouritesLink"
            kind="favorite"
          ></app-icon-button-link>
          <app-button-link [link]="createAnnouncementLink" variety="secondary"
            >Разместить объявление
          </app-button-link>
        </div>
      </div>
      <div class="header-location-block">
        <app-search
          placeholder="Поиск по объявлениям"
          class="search"
        ></app-search>
        <app-select-location></app-select-location>
        <app-button
          class="announcement-button"
          width="stretch"
          [withIcon]="true"
          [withSvg]="true"
          [height]="44"
          [routerLink]="mapUrl()"
          queryParamsHandling="merge"
          >Объявления на карте
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11 18L9 17M9 17L3 20V7L9 4M9 17V4M9 4L15 7M15 7L21 4V11.5M15 7V12M20.2 20.2L22 22M15 18C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18C21 17.2044 20.6839 16.4413 20.1213 15.8787C19.5587 15.3161 18.7956 15 18 15C17.2044 15 16.4413 15.3161 15.8787 15.8787C15.3161 16.4413 15 17.2044 15 18Z"
              [attr.stroke]="true ? '#050609' : '#878787'"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </app-button>
      </div>
    </div>
  </div>
</div>

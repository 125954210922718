import { DecimalPipe } from '@angular/common';
import { Component, inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { ButtonComponent } from '@components/ui/button/button.component';
import { DeclensePipe } from '@pipes/declense.pipe';

@Component({
  selector: 'app-buy-package-dialog',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogModule,
    MatIcon,
    DeclensePipe,
    DecimalPipe,
  ],
  templateUrl: './buy-package-dialog.component.html',
  styleUrls: [
    '../dialog.component.scss',
    './buy-package-dialog.component.scss',
  ],
})
export class BuyPackageDialogComponent {
  data = inject(MAT_DIALOG_DATA);
}

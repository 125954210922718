import { Component, DestroyRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '@components/common/header/header.component';
import { FooterComponent } from '@components/common/footer/footer.component';
import { MobileHeaderComponent } from '@components/common/mobile-header/mobile-header.component';
import { MobileFooterComponent } from '@components/common/mobile-footer/mobile-footer.component';

import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    HeaderComponent,
    MobileHeaderComponent,
    FooterComponent,
    MobileFooterComponent,
    RouterOutlet,
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent {
  destroyRef = inject(DestroyRef);
  isMobile = this.uiService.isMobile;
  removeMainScroll = this.uiService.removeMainScroll;

  constructor(private uiService: UIService) {}
}

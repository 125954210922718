<div class="main-layout" [class.without-scroll]="removeMainScroll()">
  <div class="page-content">
    @if (!isMobile()) {
      <app-header />
    }
    <router-outlet />
  </div>

  @if (isMobile()) {
    <app-mobile-footer class="footer" />
  } @else {
    <app-footer class="footer" />
  }
</div>

import {
  ROUTE_FAVOURITES,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_OPEN_CONTACTS,
  ROUTE_PROFILE_PRIORITY,
  ROUTE_SETTINGS,
  ROUTE_SUBSCRIPTION,
} from '@settings/routes';

export const profileMenuList = [
  {
    id: 'profile-menu-1',
    link: ROUTE_MY_ANNOUNCEMENTS,
    label: 'Мои объявления',
  },
  {
    id: 'profile-menu-priority',
    link: ROUTE_PROFILE_PRIORITY,
    label: 'Фильтрация',
  },
  {
    id: 'profile-menu-2',
    link: ROUTE_FAVOURITES,
    label: 'Избранное',
  },
  {
    id: 'profile-menu-3',
    link: ROUTE_OPEN_CONTACTS,
    label: 'Открытые контакты',
  },
  {
    id: 'profile-menu-4',
    link: ROUTE_SUBSCRIPTION,
    label: 'Подписка',
  },
  {
    id: 'profile-menu-5',
    link: ROUTE_SETTINGS,
    label: 'Настройки',
  },
];

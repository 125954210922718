import { Component, inject } from '@angular/core';

import { WithoutSubscriptionComponent } from './without-subscription/without-subscription.component';
import { PaymentSubscriptionComponent } from './payment-subscription/payment-subscription.component';
import { WithSubscriptionComponent } from './with-subscription/with-subscription.component';
import { ContactPackagesComponent } from './contact-packages/contact-packages.component';

import { SubscriptionService } from '@services/subscription.service';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [
    WithoutSubscriptionComponent,
    WithSubscriptionComponent,
    PaymentSubscriptionComponent,
    ContactPackagesComponent,
  ],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss',
})
export class SubscriptionComponent {
  subscriptionService = inject(SubscriptionService);
  subscription = this.subscriptionService.subscription;

  isWithoutSubscription = this.subscriptionService.isWithoutSubscription;
  isDemoSubscription = this.subscriptionService.isDemoSubscription;
}

<app-external-layout>
  <div class="full-screen-form">
    @if (!loadingStep()) {
      @switch (currentStep()) {
        @case ("set-phone-number") {
          <app-set-phone />
        }

        @case ("confirm-phone-number") {
          <app-confirm-phone />
        }

        @case ("set-profile-data") {
          <app-set-profile />
        }

        @case ("set-password") {
          <app-set-password />
        }

        @case ("confirm-email") {
          <app-confirm-email />
        }

        @default {
          <app-info-block
            variety="error"
            [description]="
              errorCurrentStep()?.formattedErrorMessage || 'Неизвестная ошибка'
            "
          />
        }
      }

      @if (currentStep() !== "confirm-email") {
        <div class="link-block">
          Есть аккаунт?
          <app-link [link]="authRoute" description="Войти" />
        </div>
      }
    } @else {
      <app-loader class="registration-loader" />
    }
  </div>
</app-external-layout>

import { Component, input, output } from '@angular/core';

@Component({
  selector: 'app-button-text',
  standalone: true,
  imports: [],
  templateUrl: './button-text.component.html',
  styleUrl: './button-text.component.scss',
})
export class ButtonTextComponent {
  disabled = input<boolean>(false);

  pressed = output();
  pressedFunction() {
    this.pressed.emit();
  }
}

<div class="technical-information-block">
  <div class="technical-information">
    © {{ year }} Закрома, все права защищены
  </div>

  <div class="technical-links-block">
    <!-- todo Ссылка -->
    <a href="" class="technical-information link"
      >Политика конфиденциальности</a
    >
    <!-- todo Ссылка -->
    <a href="" class="technical-information link"
      >Пользовательское соглашение
    </a>
  </div>
</div>

<a href="https://freematiq.com" class="dev-link" target="_blank">
  <div class="technical-information link">Разработано</div>
  <img src="assets/icons/dev-logo.svg" alt="Freematiq" />
</a>

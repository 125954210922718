@if (isMobile()) {
  <app-mobile-header variety="center">
    <app-link-back [link]="routeProfile" variety="absolute" />
    <app-title>Возможности подписки</app-title>
  </app-mobile-header>
} @else {
  <div class="title-block">
    <app-title>Возможности подписки</app-title>

    <app-button
      variety="secondary"
      [withSvg]="true"
      [height]="44"
      (pressed)="activationDemo()"
      [disabled]="loading()"
    >
      Попробовать бесплатно
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        [class.disabled-svg]="loading()"
      >
        <path
          d="M7 7L17 7V17"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 17L17 7"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </app-button>
  </div>
}

<div class="promo-blocks-wrapper">
  <div class="promo-blocks">
    <div class="promo-block top-subtitle">
      <div class="subtitle">График изменения<br />цен объявлений</div>
      <img src="assets/chart.png" alt="Promo" />
    </div>

    <div class="promo-block bottom-subtitle">
      <img src="assets/opening.png" alt="Promo" />
      <div class="subtitle">
        50 открытий<br />контактов / мес.<br />+ доп. пакеты
      </div>
    </div>

    <div class="promo-block top-subtitle">
      <div class="subtitle">Карта объявлений</div>
      <img src="assets/map.png" alt="Promo" />
    </div>

    <div class="promo-block bottom-subtitle">
      <img src="assets/analytics.png" alt="Promo" />
      <div class="subtitle">Раздел<br />Аналитики цен</div>
    </div>
  </div>
</div>

@if (isMobile()) {
  <app-button
    variety="secondary"
    [height]="44"
    [withSvg]="true"
    [height]="44"
    class="trial-button"
    (pressed)="activationDemo()"
    [disabled]="loading()"
  >
    Попробовать бесплатно
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      [class.disabled-svg]="loading()"
    >
      <path
        d="M7 7L17 7V17"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 17L17 7"
        stroke="#050609"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </app-button>
}

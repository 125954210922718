import { RouterLink, RouterLinkActive } from '@angular/router';
import { Component } from '@angular/core';

import { ButtonComponent } from '@components/ui/button/button.component';
import { profileMenuList } from '../core/profileMenuList';
import { ButtonClipboardComponent } from '@components/ui/button-clipboard/button-clipboard.component';
import { AvatarComponent } from './avatar/avatar.component';

import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-profile-sidebar',
  standalone: true,
  imports: [
    AvatarComponent,
    ButtonComponent,
    ButtonClipboardComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './profile-sidebar.component.html',
  styleUrl: './profile-sidebar.component.scss',
})
export class ProfileSidebarComponent {
  menuList = profileMenuList;

  constructor(private authService: AuthService) {}

  logOut() {
    this.authService.logOut();
  }
}

<div class="input-phone">
  @if (withLabel()) {
    <label [for]="'input_' + id()">{{ label() }}</label>
  }
  <div
    class="input-wrapper"
    [class.error]="error()"
    [class.disabled]="disabled()"
  >
    <input
      type="tel"
      inputMode="tel"
      [id]="'input_' + id()"
      [name]="name()"
      [placeholder]="placeholder()"
      [disabled]="disabled()"
      [class.empty]="value === ''"
      [(ngModel)]="value"
      (input)="onChange()"
      (change)="onChange()"
      (blur)="onTouchedFn()"
      [showMaskTyped]="showMask()"
      prefix="+7"
      mask=" 000 000 0000"
      class="input-custom"
    />
  </div>
</div>

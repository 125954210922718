<div class="input-password">
  @if (label()) {
    <label [for]="'input_' + id()"
      >{{ label() }}
      @if (requiredLabel()) {
        <sup class="required">*</sup>
      }
    </label>
  }

  <div
    class="input-wrapper"
    [class.error]="error()"
    [class.disabled]="disabled()"
  >
    <input
      [id]="'input_' + id()"
      [name]="name()"
      [disabled]="disabled()"
      [type]="showPassword() ? 'text' : 'password'"
      [placeholder]="placeholder()"
      [autocomplete]="autocomplete()"
      class="input-custom with-icon"
      [(ngModel)]="value"
      (input)="onChange()"
      (change)="onChange()"
      (blur)="onTouchedFn()"
    />
    <button type="button" (click)="toogleShowPassword()" class="button-eye">
      @if (!showPassword()) {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 14"
          fill="none"
          [class.disabled]="disabled()"
        >
          <path
            d="M8 7C8 7.53043 8.21071 8.03914 8.58579 8.41421C8.96086 8.78929 9.46957 9 10 9C10.5304 9 11.0391 8.78929 11.4142 8.41421C11.7893 8.03914 12 7.53043 12 7C12 6.46957 11.7893 5.96086 11.4142 5.58579C11.0391 5.21071 10.5304 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7Z"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19 7C16.6 11 13.6 13 10 13C6.4 13 3.4 11 1 7C3.4 3 6.4 1 10 1C13.6 1 16.6 3 19 7Z"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      } @else {
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          [class.disabled]="disabled()"
        >
          <path
            d="M8.58555 8.58691C8.21054 8.96206 7.99991 9.47082 8 10.0013C8.00009 10.5317 8.2109 11.0404 8.58605 11.4154C8.9612 11.7904 9.46996 12.0011 10.0004 12.001C10.5309 12.0009 11.0395 11.7901 11.4146 11.4149M14.681 14.673C13.2782 15.5507 11.6547 16.0109 10 16C6.4 16 3.4 14 1 10C2.272 7.88003 3.712 6.32203 5.32 5.32603M8.18 4.18003C8.77904 4.05876 9.38881 3.99845 10 4.00003C13.6 4.00003 16.6 6.00003 19 10C18.334 11.11 17.621 12.067 16.862 12.87M1 1L19 19"
            stroke="#878787"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    </button>
  </div>
</div>

<div class="set-profile-component">
  <div class="title">Введите данные</div>

  @if (!!error()) {
    <app-error
      [errorText]="error()?.formattedErrorMessage || 'Неизвестная ошибка'"
    />
  }

  <form
    #setProfileForm="ngForm"
    (ngSubmit)="setProfile(setProfileForm)"
    class="set-profile-form"
  >
    <mat-radio-group
      id="trader_kind"
      name="trader_kind"
      #trader_kind="ngModel"
      ngModel="trader"
      aria-label="trader kinds"
      class="trader-kinds-block"
    >
      <mat-radio-button
        id="trader"
        name="trader"
        [disableRipple]="true"
        value="trader"
        >Трейдер
      </mat-radio-button>
      <mat-radio-button value="farmer">Фермер</mat-radio-button>
    </mat-radio-group>

    <div>
      <app-input-label
        id="last_name"
        name="last_name"
        #last_name="ngModel"
        ngModel
        label="Фамилия"
        placeholder="Фамилия"
        [disabled]="loading()"
        [required]="true"
        [pattern]="userNamePattern"
        [maxlength]="255"
        [error]="!!last_name.invalid && !!last_name.touched"
      />
      <div *ngIf="!!last_name.invalid && !!last_name.touched" class="error">
        Обязательное поле (от 1 до 255 символов русского алфавита)
      </div>
    </div>

    <div>
      <app-input-label
        id="first_name"
        name="first_name"
        #first_name="ngModel"
        ngModel
        label="Имя"
        placeholder="Имя"
        [disabled]="loading()"
        [required]="true"
        [pattern]="userNamePattern"
        [maxlength]="255"
        [error]="!!first_name.invalid && !!first_name.touched"
      />
      <div *ngIf="!!first_name.invalid && !!first_name.touched" class="error">
        Обязательное поле (от 1 до 255 символов русского алфавита)
      </div>
    </div>

    <div>
      <app-input-label
        id="middle_name"
        name="middle_name"
        #middle_name="ngModel"
        ngModel
        label="Отчество"
        placeholder="Отчество"
        [required]="false"
        [pattern]="onlyLettersPattern"
        [disabled]="loading()"
        [error]="!!middle_name.invalid && !!middle_name.touched"
      />
      <div *ngIf="!!middle_name.invalid && !!middle_name.touched" class="error">
        До 255 символов русского алфавита
      </div>
    </div>

    <div>
      <app-input-label
        id="email"
        name="email"
        #email="ngModel"
        ngModel
        label="Email"
        placeholder="Email"
        [disabled]="loading()"
        [required]="true"
        [pattern]="emailPattern"
        [maxlength]="50"
        [error]="!!email.invalid && !!email.touched"
      />
      <div *ngIf="!!email.invalid && !!email.touched" class="error">
        Укажите корректный email
      </div>
    </div>

    <div>
      <app-input-label
        id="inn"
        name="inn"
        #inn="ngModel"
        ngModel
        label="ИНН"
        placeholder=""
        [disabled]="loading()"
        [required]="true"
        inputMask="0000000000||000000000000"
        [pattern]="innPattern"
        [error]="!!inn.invalid && !!inn.touched"
      />
      <div *ngIf="!!inn.invalid && !!inn.touched" class="error">
        Обязательное поле (10 или 12 цифр)
      </div>
    </div>

    <app-button
      variety="secondary"
      width="stretch"
      type="submit"
      class="submit-button without-top-margin mb-24"
      [disabled]="loading() || !!setProfileForm.invalid"
      >Далее
    </app-button>
  </form>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userShortName',
  standalone: true,
})
export class UserShortNamePipe implements PipeTransform {
  transform(fio: string): string {
    const words = fio.split(' ');

    if (words.length < 1 || words.length > 3) {
      return words[0];
    }

    return `${words[0]} ${words[1].slice(0, 1)}. ${words.length > 2 ? `${words[2].slice(0, 1)}.` : ''}`;
  }
}

@if (loading()) {
  <app-loader-global class="loader" />
} @else {
  @if (contactPackages().length > 0) {
    @for (package of contactPackages(); track package.id) {
      <button
        (click)="openBuyPackageDialog(package)"
        class="package"
        [class.disabled]="disabled()"
      >
        <div class="title-block">
          <div class="title">
            {{ package.openings_count }}
            {{
              package.openings_count
                | declense: ["открытие", "открытия", "открытий"]
            }}
          </div>

          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="28" cy="28" r="28" fill="#F3EF7A" />
            <path
              d="M28.0007 15.2729V40.7275M15.2734 28.0002H40.728"
              stroke="#050609"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="description-block">
          <div class="price">
            <span>Стоимость:</span>
            {{ package.price | number: "1.0" : "ru-Ru" }} руб
          </div>
          <div class="description">
            Пакет на {{ package.openings_count }}
            {{
              package.openings_count
                | declense: ["открытие", "открытия", "открытий"]
            }}
            контактов
          </div>
        </div>
      </button>
    }
  }
}

import { Component, afterNextRender, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { ExternalLayoutComponent } from '@components/layouts/external-layout/external-layout.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { TTokens } from '@models/authTypes';
import { environment } from '@environments/environment';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { TFormattedApiError } from '@utils/formattingApiError';
import { ROUTE_AUTH, ROUTE_HOME } from '@settings/routes';
import { STORAGE_REDIRECT_PATH } from '@settings/constants';

import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.sevice';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-email-confirmation',
  standalone: true,
  imports: [
    ExternalLayoutComponent,
    InfoBlockComponent,
    LinkComponent,
    LoaderComponent,
  ],
  templateUrl: './email-confirmation.component.html',
  styleUrl: './email-confirmation.component.scss',
})
export class EmailConfirmationComponent {
  authService = inject(AuthService);
  storageService = inject(StorageService);
  notificationService = inject(NotificationService);
  router = inject(Router);

  loading = signal(true);
  error = signal<string | null>(null);

  authRoute = `/${ROUTE_AUTH}`;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
  ) {
    afterNextRender(() => {
      const params = this.route.snapshot.queryParams;

      if (params['token']) {
        this.http
          .put<TTokens>(
            `${environment.getApiVersionUrl('auth/signup/confirm-email')}`,
            {
              token: params['token'],
              ...environment.getRequestData(),
            },
            {
              headers: {
                skipBearerAuthorization: 'true',
              },
              withCredentials: true,
            },
          )
          .subscribe({
            next: (response) => {
              this.error.set(null);

              // Авторизация пользователя сразу после верификации
              this.authService.afterLogIn(response);

              // Редирект на главную или по сохраненному пути
              const redirectPath = this.storageService.getItem<string>(
                STORAGE_REDIRECT_PATH,
              );

              this.router.navigate([redirectPath || ROUTE_HOME]).then(() => {
                if (redirectPath)
                  this.storageService.clearItem(STORAGE_REDIRECT_PATH);
              });

              this.loading.set(false);

              this.notificationService.success(
                'Поздравляем, вы успешно зарегистрированы в системе!',
              );
            },

            error: (error: TFormattedApiError) => {
              this.error.set(error.formattedErrorMessage);
              this.loading.set(false);
            },
          });
      } else {
        this.error.set('Подтверждение регистрации невозможно');
        this.loading.set(false);
      }
    });
  }
}

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LogoLinkComponent } from '@components/common/logo-link/logo-link.component';

@Component({
  selector: 'app-external-layout',
  standalone: true,
  imports: [LogoLinkComponent],
  templateUrl: './external-layout.component.html',
  styleUrl: './external-layout.component.scss',
})
export class ExternalLayoutComponent implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver) {}

  destroyRef = inject(DestroyRef);
  isAdaptiveLogo = signal(false);

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 1200px)'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        this.isAdaptiveLogo.set(state.breakpoints['(max-width: 1200px)']);
      });
  }
}

<div class="input-confirmation">
  <app-block-number
    (changeValue)="handleChange(0, '', $event)"
    (keyDown)="handleKeyDown(0, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />
  <app-block-number
    (changeValue)="handleChange(1, '', $event)"
    (keyDown)="handleKeyDown(1, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />
  <app-block-number
    (changeValue)="handleChange(2, '', $event)"
    (keyDown)="handleKeyDown(2, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />

  <div class="divider">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="2"
      viewBox="0 0 17 2"
      fill="none"
    >
      <path d="M0.5 1H16.5" stroke="#DEDEDE" stroke-linecap="round" />
    </svg>
  </div>

  <app-block-number
    (changeValue)="handleChange(3, '', $event)"
    (keyDown)="handleKeyDown(3, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />
  <app-block-number
    (changeValue)="handleChange(4, '', $event)"
    (keyDown)="handleKeyDown(4, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />
  <app-block-number
    (changeValue)="handleChange(5, '', $event)"
    (keyDown)="handleKeyDown(5, $event)"
    (paste)="handlePaste($event)"
    [disabled]="disabled()"
    [error]="error()"
  />
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Params } from '@angular/router';

import { INITIAL_PER_PAGE } from '@settings/constants';
import { getPaginationData } from '@utils/getPaginationHeaders';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TAnnouncement } from '@models/announcementTypes';
import { TMyAnnouncementsStatusesCount } from './myAnnouncementsTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class MyAnnouncementsService {
  #myAnnouncementsList = signal<TAnnouncement[]>([]);
  myAnnouncementsList = computed(this.#myAnnouncementsList);

  #myAnnouncementsStatusesCount = signal<number[]>([]);
  myAnnouncementsStatusesCount = computed(this.#myAnnouncementsStatusesCount);

  #currentPage = signal<number>(0);
  currentPage = computed(this.#currentPage);

  #totalPage = signal<number>(0);
  totalPage = computed(this.#totalPage);

  #perPage = signal<number>(0);
  perPage = computed(this.#perPage);

  #totalCount = signal<number>(0);
  totalCount = computed(this.#totalCount);

  #loader = signal<boolean>(true);
  loader = computed(this.#loader);

  #infiniteLoader = signal<boolean>(false);
  infiniteLoader = computed(this.#infiniteLoader);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(private http: HttpClient) {}

  // Список "моих" объявлений
  loadMyAnnouncements({
    params,
    isInfinite,
  }: {
    params: Params;
    isInfinite?: boolean;
  }): void {
    if (this.#currentPage() >= this.#totalPage() && this.#currentPage() !== 0)
      return;

    if (isInfinite) {
      this.#infiniteLoader.set(true);
    } else {
      this.#loader.set(true);
    }

    this.#currentPage.set(this.#currentPage() + 1);

    const allParams: Params = {
      'per-page': INITIAL_PER_PAGE,
      page: this.#currentPage(),
      ...params,
    };

    this.http
      .get<TAnnouncement[]>(
        `${environment.getApiVersionUrl('announcements/my')}`,
        {
          params: allParams,
          transferCache: {
            includeHeaders: [
              'X-Pagination-Current-Page',
              'X-Pagination-Page-Count',
              'X-Pagination-Per-Page',
              'X-Pagination-Total-Count',
            ],
          },
          observe: 'response',
        },
      )
      .subscribe({
        next: (response) => {
          const responsePagination =
            getPaginationData<TAnnouncement[]>(response);

          this.#currentPage.set(responsePagination.currentPage);
          this.#totalPage.set(responsePagination.totalPage);
          this.#perPage.set(responsePagination.perPage);
          this.#totalCount.set(responsePagination.totalCount);

          this.#myAnnouncementsList.set([
            ...this.#myAnnouncementsList(),
            ...responsePagination.data,
          ]);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          if (isInfinite) {
            this.#infiniteLoader.set(false);
          } else {
            this.#loader.set(false);
          }
        },
      });
  }

  // Список количества объявлений по статусам
  loadMyAnnouncementsStatusesCount(): void {
    this.http
      .get<TMyAnnouncementsStatusesCount>(
        `${environment.getApiVersionUrl('announcements/my/statuses-count')}`,
      )
      .subscribe({
        next: (response) => {
          this.#error.set(null);
          this.#myAnnouncementsStatusesCount.set(Object.values(response));
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
        },
      });
  }

  resetMyAnnouncements() {
    this.#myAnnouncementsList.set([]);
    this.#currentPage.set(0);
    this.#totalPage.set(0);
    this.#perPage.set(0);
    this.#totalCount.set(0);
    this.#loader.set(true);
    this.#infiniteLoader.set(false);
    this.#error.set(null);
  }
}

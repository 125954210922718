@if (history()) {
  <div
    class="price-history-recycles"
    [class.vertically]="orientation() === 'vertically'"
  >
    <div class="avg-block" [class.vertically]="orientation() === 'vertically'">
      {{ title() }}
      @if (history()?.avg_price_with_vat) {
        <span
          >{{ history()?.avg_price_with_vat | price: " руб./т" }}
          <sup>Без НДС</sup></span
        >
      }

      @if (history()?.avg_price_without_vat) {
        <span
          >{{ history()?.avg_price_without_vat | price: " руб./т" }}
          <sup>С НДС</sup></span
        >
      }

      @if (
        !history()?.avg_price_with_vat && !history()?.avg_price_without_vat
      ) {
        <span>-</span>
      }
    </div>
    <div class="history-block">
      История цен
      @if (history()?.min_price_with_vat || history()?.max_price_with_vat) {
        <span
          >от {{ history()?.min_price_with_vat | price: " руб" }} до
          {{ history()?.max_price_with_vat | price: " руб" }}
          <sup>Без НДС</sup></span
        >
      }

      @if (
        history()?.min_price_without_vat || history()?.max_price_without_vat
      ) {
        <span
          >от {{ history()?.min_price_without_vat | price: " руб" }} до
          {{
            history()?.max_price_without_vat
              | price
                : "
          руб"
          }}
          <sup>С НДС</sup></span
        >
      }

      @if (isNullData()) {
        <span>-</span>
      }
    </div>
  </div>
}

<div
  [ngClass]="{
    'promo-image-wrapper': true,
    small: size() === 'small',
    middle: size() === 'middle',
    large: size() === 'large',
  }"
>
  @if (image()) {
    <img [src]="image()" alt="Image" />
  }
</div>

<app-back-button (click)="goBack()" />

<form
  name="confirm-phone-form"
  (submit)="confirmPhone($event)"
  class="confirm-phone-number"
>
  <div class="title mb-16">Подтверждение телефона</div>

  <div class="label mb-32">
    @if (!phoneNumber()) {
      На ваш номер отправлен код подтверждения, введите его:
    } @else {
      На номер <span>{{ phoneNumber() | phoneNumber }}</span> отправлен код
      подтверждения, введите его:
    }
  </div>

  @if (!!error()) {
    <app-error
      [errorText]="error()?.formattedErrorMessage || 'Неизвестная ошибка'"
    />
  }

  <app-input-confirmation
    [disabled]="loading()"
    [error]="!!error()"
    (confirmCode)="changeIsValidCode($event)"
  />

  @if (!isGetCode()) {
    <div class="label mb-32">Отправить новый код через {{ timerValue() }}</div>
  } @else {
    <app-button-text (pressed)="reSend()" [disabled]="loading()" class="mb-32"
      >Отправить новый код</app-button-text
    >
  }

  <app-button
    variety="secondary"
    width="stretch"
    type="submit"
    class="submit-button without-top-margin mb-24"
    [disabled]="loading() || !isValidCode()"
    >Далее</app-button
  >
</form>

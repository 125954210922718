<mat-icon class="dialog-close" mat-dialog-close>close</mat-icon>

<div class="dialog-title">Удалить карту</div>

<mat-dialog-content class="dialog-content">
  Вы уверены, что хотите удалить карту из списка привязанных?
</mat-dialog-content>

<mat-dialog-actions class="dialog-footer">
  <app-button variety="secondary" [mat-dialog-close]="true"
    >Удалить карту</app-button
  >
  <app-button variety="transparent" mat-dialog-close>Назад</app-button>
</mat-dialog-actions>

@if (history() && !isNullData()) {
  <div
    class="price-history"
    [class.vertically]="orientation() === 'vertically'"
  >
    <div
      class="avg-block"
      [class.recycles]="variety() === 'recycles'"
      [class.vertically]="orientation() === 'vertically'"
    >
      {{ title() }}
      <span>{{ history()?.avg | price: " руб./т" }}</span>
    </div>
    <div class="history-block">
      История цен
      <span
        >от {{ history()?.min | price: " руб" }} до
        {{ history()?.max | price: " руб" }}</span
      >
    </div>
  </div>
}

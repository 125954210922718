<div class="profile-page">
  @if (loading()) {
    <app-loader-global class="loader" />
  } @else {
    @if (!isMobile()) {
      <div class="profile-sidebar">
        <app-profile-sidebar />
      </div>

      <div class="profile-content">
        <router-outlet />
      </div>
    } @else {
      <router-outlet />
    }
  }
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import { TBasicContacts, TFooterContacts } from './contactsTypes';
import { TFormattedApiError } from '@utils/formattingApiError';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ContactsService {
  #basicContacts = signal<TBasicContacts | null>(null);
  basicContacts = computed(this.#basicContacts);

  #footerContacts = signal<TFooterContacts | null>(null);
  footerContacts = computed(this.#footerContacts);

  #loadingFooterContacts = signal<boolean>(true);
  loadingFooterContacts = computed(this.#loadingFooterContacts);

  #footerContactsError = signal<string | null>(null);
  footerContactsError = computed(this.#footerContactsError);

  constructor(private http: HttpClient) {}

  loadBasicContacts(): Observable<TBasicContacts> {
    return this.http.get<TBasicContacts>(
      `${environment.getApiVersionUrl('contacts/basic')}`,
      {
        headers: {
          skipBearerAuthorization: 'true',
        },
      },
    );
  }

  loadFooterContacts(): void {
    this.#loadingFooterContacts.set(true);

    this.http
      .get<TFooterContacts>(
        `${environment.getApiVersionUrl('contacts/footer')}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .subscribe({
        next: (response) => {
          this.#footerContactsError.set(null);
          this.#footerContacts.set(response);
          this.#loadingFooterContacts.set(false);
        },
        error: (error: TFormattedApiError) => {
          if (error.formattedErrorMessage)
            this.#footerContactsError.set(error.formattedErrorMessage);
          this.#loadingFooterContacts.set(false);
        },
      });
  }

  setBasicContacts(data: TBasicContacts): void {
    this.#basicContacts.set(data);
  }

  setFooterContacts(data: TFooterContacts): void {
    this.#footerContacts.set(data);
  }
}

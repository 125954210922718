import {
  ApplicationConfig,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { TitleCasePipe, registerLocaleData } from '@angular/common';
import { provideRouter } from '@angular/router';

import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { TimerService } from '@services/timer.service';

import { authHeaderInterceptor } from './interceptors/auth-header.interceptor';
import { responseErrorInterceptor } from './interceptors/response-error.interceptor';

import { routes } from './app.routes';
import { provideYConfig } from 'angular-yandex-maps-v3';
import { environment } from '@environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      // withInMemoryScrolling({
      //   scrollPositionRestoration: 'top',
      //   anchorScrolling: 'enabled',
      // }),
    ),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        filter: (req) =>
          !req.url.startsWith(
            `${environment.getApiVersionUrl('announcements')}`,
          ),
      }),
    ),

    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([authHeaderInterceptor, responseErrorInterceptor]),
    ),
    provideEnvironmentNgxMask(),
    provideYConfig({ apikey: environment.yandex_maps_key }),
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    TitleCasePipe,
    TimerService,
    provideCharts(withDefaultRegisterables()),
  ],
};

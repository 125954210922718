import { Component, DestroyRef, OnInit, inject, signal } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import chunk from 'lodash-es/chunk';

import { promoItemsList } from './promoItemsList';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { PromoImageComponent } from './promo-image/promo-image.component';
import { MOBILE_BREAKPOINT } from '@settings/constants';

@Component({
  selector: 'app-promo',
  standalone: true,
  imports: [ButtonLinkComponent, PromoImageComponent],
  templateUrl: './promo.component.html',
  styleUrl: './promo.component.scss',
})
export class PromoComponent implements OnInit {
  constructor(private breakpointObserver: BreakpointObserver) {}

  itemsListThreeColumn = chunk(
    promoItemsList,
    Math.ceil(promoItemsList.length / 3),
  );
  itemsListTwoColumn = chunk(
    promoItemsList,
    Math.ceil(promoItemsList.length / 2),
  );
  itemsListOneColumn = chunk(promoItemsList, promoItemsList.length);

  items = signal(this.itemsListThreeColumn);

  // todo Добавить ссылку
  trialLink = '/';

  destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 1400px)', MOBILE_BREAKPOINT])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[MOBILE_BREAKPOINT]) {
          this.items.set(this.itemsListOneColumn);
        }

        if (
          state.breakpoints['(max-width: 1400px)'] &&
          !state.breakpoints[MOBILE_BREAKPOINT]
        ) {
          this.items.set(this.itemsListTwoColumn);
        }

        if (!state.matches) this.items.set(this.itemsListThreeColumn);
      });
  }
}

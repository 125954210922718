<div class="image-wrapper">
  @if (announcement().main_image) {
    <img
      [src]="announcement().main_image.url"
      alt="Фотография"
      class="main-photo"
    />
  }

  <app-class-label
    [classification]="announcement().classification"
    class="class"
  ></app-class-label>
  <app-category-label
    [isGrainWaste]="announcement().category.code === 'grain_waste'"
    [label]="
      announcement().cereal !== null
        ? announcement().cereal?.name
        : announcement().category.label
    "
    class="category"
  ></app-category-label>
</div>

<a
  [routerLink]="[menuItem().link]"
  [ngClass]="{
    'menu-link': true,
    white: variety() === 'white',
    black: variety() === 'black',
    'adaptive-text': adaptiveText(),
  }"
  >{{ menuItem().name }}</a
>

<div class="advertising-small-block">
  <div class="advertising-data">
    <div class="title">Зернообработчику</div>
    Бесплатный пробный месяц подписки включает:
    <ul>
      <li>Открытие контактов – 50 шт</li>
      <li>Предложение связаться – 50 шт</li>
    </ul>
  </div>

  <div class="small-banner">
    <app-button-link [link]="trialLink" class="trial-button"
      >Попробовать бесплатно
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7 7L17 7V17"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 17L17 7"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg>
    </app-button-link>
    <!-- В адаптивной версии кнопка другого цвета -->
    <app-button-link
      variety="secondary"
      [link]="trialLink"
      class="trial-button-adaptive"
      >Попробовать бесплатно
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7 7L17 7V17"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 17L17 7"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg>
    </app-button-link>
  </div>
</div>
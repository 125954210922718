import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { ReferencesService } from '@services/references.service';
import { ContactsService } from '@components/pages/contacts/core/contacts.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'zakroma';

  constructor(
    private referencesService: ReferencesService,
    private contactsService: ContactsService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.contactsService.loadFooterContacts();
    this.referencesService.loadAllReferences();

    // Проверка авторизованности пользователя
    this.authService.checkAuth();
  }
}

import { Component, inject, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DecimalPipe, NgIf } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';

import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { TAnnouncement } from '@models/announcementTypes';
import { MyAnnouncementsImageComponent } from './my-announcements-image/my-announcements-image.component';
import { MyAnnouncementsDescriptionComponent } from './my-announcements-description/my-announcements-description.component';
import {
  ROUTE_ANNOUNCEMENT,
  ROUTE_ANNOUNCEMENT_EDIT,
  ROUTE_MY_ANNOUNCEMENTS,
  ROUTE_MY_ANNOUNCEMENTS_DISABLE,
  ROUTE_PROFILE,
} from '@settings/routes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { DisabledAnnouncementDialogComponent } from '@components/common/dialog/disabled-announcement-dialog/disabled-announcement-dialog.component';
import { DeleteAnnouncementDialogComponent } from '@components/common/dialog/delete-announcement-dialog/delete-announcement-dialog.component';
import { InfoBlockAnnouncementComponent } from '@components/common/info-block-announcement/info-block-announcement.component';
import { TBreadcrumbsSource } from '@components/common/breadcrumbs/breadcrumb';

import { MyAnnouncementsService } from '../core/myAnnouncements.service';
import { AnnouncementService } from '@services/announcement.service';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-my-announcements-card',
  standalone: true,
  imports: [
    ButtonComponent,
    ButtonLinkComponent,
    RouterLink,
    MyAnnouncementsImageComponent,
    MyAnnouncementsDescriptionComponent,
    DecimalPipe,
    MatTooltipModule,
    NgIf,
    InfoBlockAnnouncementComponent,
  ],
  templateUrl: './my-announcements-card.component.html',
  styleUrl: './my-announcements-card.component.scss',
})
export class MyAnnouncementsCardComponent {
  announcementService = inject(AnnouncementService);
  myAnnouncementsService = inject(MyAnnouncementsService);
  notificationService = inject(NotificationService);
  router = inject(Router);
  readonly dialog = inject(MatDialog);

  variety = input.required<'active' | 'disabled' | 'draft'>();
  isLastCard = input<boolean>(false);

  protected readonly ROUTE_ANNOUNCEMENT_EDIT = ROUTE_ANNOUNCEMENT_EDIT;
  protected readonly ROUTE_ANNOUNCEMENT = ROUTE_ANNOUNCEMENT;

  announcement = input.required<TAnnouncement>();
  source = input<TBreadcrumbsSource>();
  loading = this.announcementService.loading;

  openDisabledDialog() {
    const dialogRef = this.dialog.open(DisabledAnnouncementDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.disabledAnnouncement();
    });
  }

  openDeleteDialog(status_value: 'draft' | 'disabled') {
    const dialogRef = this.dialog.open(DeleteAnnouncementDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.announcementDelete(status_value);
    });
  }

  // Снятие с публикации
  disabledAnnouncement() {
    const slug = this.announcement()?.slug;

    if (slug) {
      this.announcementService.setLoading(true);

      this.announcementService.disabledAnnouncement(slug).subscribe({
        next: () => {
          this.myAnnouncementsService.loadMyAnnouncementsStatusesCount();

          this.announcementService.setLoading(false);
          this.router.navigate([
            '/',
            ROUTE_PROFILE,
            ROUTE_MY_ANNOUNCEMENTS,
            ROUTE_MY_ANNOUNCEMENTS_DISABLE,
          ]);

          this.notificationService.success('Объявление снято с публикации');
        },
        error: (error: TFormattedApiError) => {
          this.announcementService.setLoading(false);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      console.error('Ошибка получения slug объявления');
    }
  }

  // Публикация неактивного объявления
  announcementActive() {
    const slug = this.announcement()?.slug;

    if (slug) {
      this.announcementService.setLoading(true);

      this.announcementService.activeAnnouncement(slug).subscribe({
        next: () => {
          this.myAnnouncementsService.loadMyAnnouncementsStatusesCount();

          this.announcementService.setLoading(false);
          this.router.navigate(['/', ROUTE_PROFILE, ROUTE_MY_ANNOUNCEMENTS]);

          this.notificationService.success('Объявление успешно опубликовано');
        },
        error: (error: TFormattedApiError) => {
          this.announcementService.setLoading(false);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      console.error('Ошибка получения slug объявления');
    }
  }

  // Удаление объявления
  announcementDelete(status_value: 'draft' | 'disabled') {
    const slug = this.announcement()?.slug;

    if (slug) {
      this.announcementService.setLoading(true);

      this.announcementService.deleteAnnouncement(slug).subscribe({
        next: () => {
          this.myAnnouncementsService.loadMyAnnouncementsStatusesCount();

          this.announcementService.setLoading(false);

          this.myAnnouncementsService.resetMyAnnouncements();
          this.myAnnouncementsService.loadMyAnnouncements({
            params: { status_value },
          });

          this.notificationService.success('Объявление успешно удалено');
        },
        error: (error: TFormattedApiError) => {
          this.announcementService.setLoading(false);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
    } else {
      console.error('Ошибка получения slug объявления');
    }
  }
}

import { Component, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RecaptchaComponent, RecaptchaModule } from 'ng-recaptcha-2';

import { PasswordRecoveryService } from '../../core/passwordRecovery.service';
import { PHONE_PATTERN } from '@settings/validationSettings';
import { LabelComponent } from '@components/ui/label/label.component';
import { InputPhoneComponent } from '@components/ui/input-phone/input-phone.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { LinkComponent } from '@components/ui/link/link.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { TRecoverySetPhoneRequest } from '../../core/passwordRecoveryTypes';
import { BackLinkComponent } from '@components/common/back-link/back-link.component';
import { ROUTE_AUTH } from '@settings/routes';

import { environment } from '@environments/environment';

@Component({
  selector: 'app-recovery-set-phone',
  standalone: true,
  imports: [
    LabelComponent,
    InputPhoneComponent,
    ButtonComponent,
    LinkComponent,
    FormsModule,
    InfoBlockComponent,
    BackLinkComponent,
    RecaptchaModule,
  ],
  templateUrl: './recovery-set-phone.component.html',
  styleUrl: './recovery-set-phone.component.scss',
})
export class RecoverySetPhoneComponent {
  captcha = viewChild<RecaptchaComponent>('captcha');

  error = this.passwordRecoveryService.error;
  loading = this.passwordRecoveryService.loading;

  key = environment.captcha_key;

  recoverySetPhoneForm: TRecoverySetPhoneRequest = {
    phone_number: this.passwordRecoveryService.phoneNumber().slice(2, 12),
    recaptcha: null,
  };

  phonePattern = PHONE_PATTERN;
  authRoute = `/${ROUTE_AUTH}`;

  constructor(private passwordRecoveryService: PasswordRecoveryService) {}

  resolved(captchaResponse: string | null) {
    this.recoverySetPhoneForm.recaptcha = captchaResponse;
  }

  recoverySetPhone(event: Event) {
    event.preventDefault();

    this.passwordRecoveryService
      .recoverySetPhoneNumber({
        phone_number: '+7' + this.recoverySetPhoneForm.phone_number,
        recaptcha: this.recoverySetPhoneForm.recaptcha,
      })
      .subscribe({
        next: () => {
          this.captcha()?.reset();
        },
        error: () => {
          this.captcha()?.reset();
        },
      });
  }
}

import { Component, input } from '@angular/core';

import { CategoryLabelComponent } from '@components/pages/home/announcement-card/category-label/category-label.component';
import { ClassLabelComponent } from '@components/pages/home/announcement-card/class-label/class-label.component';
import { TAnnouncement } from '@models/announcementTypes';
import { TAnnouncementListData } from '@components/pages/home/core/announcementsListTypes';

@Component({
  selector: 'app-my-announcements-image',
  standalone: true,
  imports: [ClassLabelComponent, CategoryLabelComponent],
  templateUrl: './my-announcements-image.component.html',
  styleUrl: './my-announcements-image.component.scss',
})
export class MyAnnouncementsImageComponent {
  announcement = input.required<TAnnouncement | TAnnouncementListData>();
}

import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { catchError, Observable, Subscription, tap, throwError } from 'rxjs';

import {
  TCurrentStep,
  TCurrentStepResponse,
  TSetPhoneRequest,
  TSetProfileRequest,
} from './registrationTypes';
import { TCreatePasswordRequest } from '@models/commonTypes';
import { TBooleanResponse } from '@models/authTypes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TimerService } from '@services/timer.service';
import { NotificationService } from '@services/notification.service';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  reSendSmsTimer!: Subscription;

  // Текущий шаг регистрации
  #currentStep = signal<TCurrentStep | null>(null);
  currentStep = computed(this.#currentStep);

  #loadingStep = signal<boolean>(true);
  loadingStep = computed(this.#loadingStep);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #error = signal<TFormattedApiError | null>(null);
  error = computed(this.#error);

  #errorCurrentStep = signal<TFormattedApiError | null>(null);
  errorCurrentStep = computed(this.#errorCurrentStep);

  // Номер телефона
  #phoneNumber = signal<string>('');
  phoneNumber = computed(this.#phoneNumber);

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private timerService: TimerService,
  ) {}

  loadCurrentStep(): void {
    this.#loadingStep.set(true);

    this.http
      .get<TCurrentStepResponse>(
        `${environment.getApiVersionUrl('auth/signup/current-step')}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .subscribe({
        next: (response) => {
          this.#errorCurrentStep.set(null);
          this.#currentStep.set(response.step);
          this.#loadingStep.set(false);
        },

        error: (error) => {
          this.#errorCurrentStep.set(error);
          this.#loadingStep.set(false);

          console.error(error);
        },
      });
  }

  setPhoneNumber(data: TSetPhoneRequest): Observable<TBooleanResponse> {
    this.#loading.set(true);
    this.#phoneNumber.set(data.phone_number);

    return this.http
      .post<TBooleanResponse>(
        `${environment.getApiVersionUrl('auth/signup/set-phone-number')}`,
        {
          ...data,
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .pipe(
        tap(() => {
          this.#error.set(null);
          this.#currentStep.set('confirm-phone-number');
          this.#loading.set(false);

          if (this.reSendSmsTimer) this.reSendSmsTimer.unsubscribe();
          this.reSendSmsTimer = this.timerService.createTimer().subscribe();
        }),
        catchError((error: TFormattedApiError) => {
          this.#error.set(error);
          this.#loading.set(false);

          console.error(error);
          return throwError(() => error);
        }),
      );
  }

  reSendSms(): void {
    this.#loading.set(true);

    this.http
      .post(
        `${environment.getApiVersionUrl('auth/signup/re-send-sms')}`,
        {
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .subscribe({
        next: () => {
          this.#error.set(null);
          this.#loading.set(false);

          this.notificationService.success('Новый код успешно отправлен');

          if (this.reSendSmsTimer) this.reSendSmsTimer.unsubscribe();
          this.reSendSmsTimer = this.timerService.createTimer().subscribe();
        },

        error: (error) => {
          this.#error.set(error);
          this.#loading.set(false);

          console.error(error);
        },
      });
  }

  confirmPhone(code: string): void {
    this.#loading.set(true);

    this.http
      .post<TBooleanResponse>(
        `${environment.getApiVersionUrl('auth/signup/confirm-phone-number')}`,
        {
          confirm_code: code,
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .subscribe({
        next: () => {
          this.#error.set(null);
          this.#currentStep.set('set-profile-data');
          this.#loading.set(false);

          if (this.reSendSmsTimer) this.reSendSmsTimer.unsubscribe();
        },

        error: (error) => {
          this.#error.set(error);
          this.#loading.set(false);

          console.error(error);
        },
      });
  }

  setProfileData(data: TSetProfileRequest): void {
    this.#loading.set(true);

    this.http
      .post<TBooleanResponse>(
        `${environment.getApiVersionUrl('auth/signup/set-profile-data')}`,
        {
          ...data,
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .subscribe({
        next: () => {
          this.#error.set(null);
          this.#currentStep.set('set-password');
          this.#loading.set(false);
        },

        error: (error) => {
          this.#error.set(error);
          this.#loading.set(false);

          console.error(error);
        },
      });
  }

  setPassword(data: TCreatePasswordRequest): void {
    this.#loading.set(true);

    this.http
      .post<TBooleanResponse>(
        `${environment.getApiVersionUrl('auth/signup/set-password')}`,
        {
          ...data,
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
          withCredentials: true,
        },
      )
      .subscribe({
        next: () => {
          this.#error.set(null);
          this.#currentStep.set('confirm-email');
          this.#loading.set(false);
        },

        error: (error) => {
          this.#error.set(error);
          this.#loading.set(false);

          console.error(error);
        },
      });
  }

  setCurrentStep = (newValue: TCurrentStep) => {
    this.#currentStep.set(newValue);
  };

  resetError = () => {
    this.#error.set(null);
  };
}

export const getTimeFromMilliseconds = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = (milliseconds % 60000) / 1000;

  if (minutes === 0) {
    return seconds + ' сек';
  }

  return minutes + ' мин ' + seconds + ' сек';
};

import { Component, computed } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
  ROUTE_ANNOUNCEMENT_CREATE,
  ROUTE_AUTH,
  ROUTE_FAVOURITES,
  ROUTE_PROFILE,
} from '@settings/routes';
import { AuthService } from '@services/auth.service';
import { LogoLinkComponent } from '../logo-link/logo-link.component';
import { headerMenuList, headerMenuListOpen } from '@settings/headerMenuLists';
import { ButtonComponent } from '@components/ui/button/button.component';
import { SearchComponent } from '@components/ui/search/search.component';
import { MenuLinkComponent } from '@components/ui/menu-link/menu-link.component';
import { IconButtonComponent } from '@components/ui/icon-button/icon-button.component';
import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { SelectLocationComponent } from '@components/ui/select-location/select-location.component';
import { IconButtonLinkComponent } from '@components/ui/icon-button-link/icon-button-link.component';
import { ProfileMenuComponent } from '@components/common/profile-menu/profile-menu.component';
import { TMenuItems } from '@models/commonTypes';
import { MapLabelsService } from '@components/pages/map/map-labels.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MenuLinkComponent,
    RouterLink,
    ButtonComponent,
    ButtonLinkComponent,
    IconButtonComponent,
    IconButtonLinkComponent,
    SearchComponent,
    LogoLinkComponent,
    SelectLocationComponent,
    ProfileMenuComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  mapUrl = this.map.mapUrl;

  constructor(
    private authService: AuthService,
    private readonly map: MapLabelsService,
  ) {}

  isAuth = this.authService.isAuth;
  menuList = computed<TMenuItems>(() => {
    if (true === this.isAuth()) {
      return headerMenuList;
    }
    if (false === this.isAuth()) {
      return headerMenuListOpen;
    }

    return [];
  });

  authLink = `/${ROUTE_AUTH}`;
  favouritesLink = `/${ROUTE_PROFILE}/${ROUTE_FAVOURITES}`;
  createAnnouncementLink = `/${ROUTE_ANNOUNCEMENT_CREATE}`;
}

import { Component, afterNextRender } from '@angular/core';

import { ROUTE_REGISTRATION } from '@settings/routes';
import { LinkComponent } from '@components/ui/link/link.component';
import { ExternalLayoutComponent } from '@components/layouts/external-layout/external-layout.component';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { InfoBlockComponent } from '@components/common/info-block/info-block.component';
import { PasswordRecoveryService } from './core/passwordRecovery.service';
import { RecoverySetPhoneComponent } from './steps/recovery-set-phone/recovery-set-phone.component';
import { RecoveryConfirmPhoneComponent } from './steps/recovery-confirm-phone/recovery-confirm-phone.component';
import { RecoverySetPasswordComponent } from './steps/recovery-set-password/recovery-set-password.component';
import { RecoveryPasswordChangedComponent } from './steps/recovery-password-changed/recovery-password-changed.component';

@Component({
  selector: 'app-password-recovery',
  standalone: true,
  imports: [
    ExternalLayoutComponent,
    LinkComponent,
    LoaderComponent,
    InfoBlockComponent,
    RecoverySetPhoneComponent,
    RecoveryConfirmPhoneComponent,
    RecoverySetPasswordComponent,
    RecoveryPasswordChangedComponent,
  ],
  templateUrl: './password-recovery.component.html',
  styleUrl: './password-recovery.component.scss',
})
export class PasswordRecoveryComponent {
  currentStep = this.passwordRecoveryService.currentStep;
  loadingStep = this.passwordRecoveryService.loadingStep;
  errorCurrentStep = this.passwordRecoveryService.errorCurrentStep;

  registrationRoute = `/${ROUTE_REGISTRATION}`;

  constructor(private passwordRecoveryService: PasswordRecoveryService) {
    afterNextRender(() => {
      this.passwordRecoveryService.loadRecoveryCurrentStep();
    });
  }
}
